import {
  BarChartOutlined,
  DownloadOutlined,
  MoreHorizOutlined,
  TableViewOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@mui/icons-material";
import { Button, SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState, MouseEvent } from "react";

interface ActionProps {
  viewToggleValue?: string[];
  onViewToggle?: (value: ("chart" | "data")[]) => void;
  onZoomClick?: (value: "in" | "out") => void;
  onMoreClick?: () => void;
}

function ActionButtons(props: ActionProps) {
  const [view, setView] = useState<("chart" | "data")[]>(["chart"]);
  const handleViewChange = (
    _: MouseEvent<HTMLElement>,
    values: ("chart" | "data")[]
  ) => {
    setView(values);
    props.onViewToggle!(values);
  };

  return (
    <Stack direction="row" spacing={2}>
      {props.onViewToggle && (
        <ToggleButtonGroup
          size="small"
          value={props.viewToggleValue || view}
          onChange={handleViewChange}
        >
          <ToggleButton value="chart" sx={{ pointerEvents: "none" }}>
            <BarChartOutlined />
          </ToggleButton>
          <ToggleButton value="data">
            <TableViewOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {props.onZoomClick && (
        <ToggleButtonGroup size="small">
          <ToggleButton value="in" onClick={() => props.onZoomClick!("in")}>
            <ZoomInOutlined />
          </ToggleButton>
          <ToggleButton value="out" onClick={() => props.onZoomClick!("out")}>
            <ZoomOutOutlined />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {props.onMoreClick && (
        <IconButton onClick={() => props.onMoreClick!()}>
          <MoreHorizOutlined />
        </IconButton>
      )}
    </Stack>
  );
}

interface Props {
  title?: string | React.ReactNode;
  subheader?: string | React.ReactNode;
  viewToggleValue?: string[];
  sx?: SxProps;
  contentSx?: SxProps;
  onViewToggle?: (value: ("chart" | "data")[]) => void;
  onZoomClick?: (value: "in" | "out") => void;
  onMoreClick?: () => void;
  onExport?: () => void;
}

const GradientCard: React.FC<Props> = (props) => {
  return (
    <Card sx={{ height: "100%", ...props.sx }}>
      <Box
        sx={{
          background: "linear-gradient(90deg, #293252, #0097A7)",
          height: 16,
        }}
      ></Box>
      {(props.title || props.subheader) && (
        <CardHeader
          title={props.title}
          subheader={props.subheader}
          action={
            props.onExport ? (
              <Button
                startIcon={<DownloadOutlined />}
                onClick={props.onExport}
                sx={{ color: "text.primary" }}
              >
                Export
              </Button>
            ) : (
              <ActionButtons
                onViewToggle={props.onViewToggle}
                onZoomClick={props.onZoomClick}
                onMoreClick={props.onMoreClick}
                viewToggleValue={props.viewToggleValue}
              />
            )
          }
        />
      )}
      <CardContent
        sx={{
          padding: 2,
          "&:last-child": { paddingBottom: 2 },
          ...props.contentSx,
        }}
      >
        {props.children}
      </CardContent>
    </Card>
  );
};

export default GradientCard;
