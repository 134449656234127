import { AddOutlined, OpenInNewOutlined } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FileStatus, ROUTES } from "common/constants";
import { useSelector } from "common/store";
import { Role } from "models/team";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { selectRoleByTeam } from "slices/teamSlice";
import { getRelativeTimeWithPreposition } from "utils/getRelativeTime";
import RevPulse from "assets/images/RevPulseLogo.png";

import ContextMenuDashboard from "./ContextMenuDashboard";

interface Props {
  dashboardType: "RRI" | "CPI";
  isPlaceholder?: boolean | undefined;
  status?: FileStatus;
  isError?: boolean;
  createdBy?: string | undefined;
  updatedBy?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
  lastActivity?: string | undefined;
  role?: Role | null;
  onReportNameClick?: () => void;
  onCreateReportClick?: () => void;
  dashboardId?: string;
}

const DashboardCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const role = useSelector(selectRoleByTeam);
  const params = useParams();
  const canViewReport = props.role || role === Role.Admin;
  const canEditReport = props.role === Role.ReportEditor || role === Role.Admin;
  const { t } = useTranslation();
  const color =
    props.dashboardType === "RRI" ? "primary.main" : "secondary.main";

  function handleOnOptionSelect(optionId: string): void {
    if (optionId === "settings")
      navigate(
        ROUTES.REPORT_PARAMETERS(params.id!, params.wsId!, props.dashboardId!),
        {
          state: {
            from: window.location.pathname,
            page: t("Dashboards.WorkspaceHome"),
          },
        }
      );
    if (optionId === "view") props.onReportNameClick?.();
    if (optionId === "manage") {
      navigate(
        ROUTES.DASHBOARD_SOURCE(params.id!, params.wsId!, props.dashboardId!),
        {
          state: {
            from: window.location.pathname,
            page: t("Dashboards.WorkspaceHome"),
          },
        }
      );
    }
  }

  const shouldEnableReportNameClick =
    !props.isError &&
    (props.status === FileStatus.ReportGenerated ||
      props.status === FileStatus.DataImported ||
      props.status === FileStatus.DataValidated);

  return (
    <Card
      elevation={0}
      sx={{
        borderColor: color,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: 2,
      }}
    >
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={RevPulse} sx={{ bgcolor: "transparent" }} />
            <Link
              variant="h5"
              color={color}
              underline="none"
              onClick={() =>
                canEditReport &&
                shouldEnableReportNameClick &&
                props.onReportNameClick?.()
              }
              sx={{
                cursor: shouldEnableReportNameClick ? "pointer" : undefined,
              }}
            >
              {t("Dashboards.Report.ReportName", { name: t("RevPulse") })}
            </Link>
            <Box flex={1}></Box>
            {!(props.isPlaceholder ?? false) &&
              canViewReport &&
              canEditReport && (
                <ContextMenuDashboard
                  role={props.role}
                  disabledView={
                    props.status !== FileStatus.ReportGenerated ||
                    props.isError === true
                  }
                  onOptionSelect={handleOnOptionSelect}
                />
              )}
          </Stack>
        }
        subheader={
          props.createdBy &&
          props.createdAt &&
          `Created by ${props.createdBy} ${getRelativeTimeWithPreposition(
            props.createdAt
          )}`
        }
        subheaderTypographyProps={{ variant: "caption", color, marginTop: 0.5 }}
      />
      <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
        {!props.isPlaceholder && props.children}
        {canEditReport && props.isPlaceholder && (
          <Box display="flex">
            <Button
              size="large"
              variant="outlined"
              color={props.dashboardType === "RRI" ? "primary" : "secondary"}
              sx={{ borderRadius: 120, marginY: 6, marginX: "auto" }}
              startIcon={<AddOutlined />}
              onClick={props.onCreateReportClick}
            >
              {t("Dashboards.Report.CreateReport", {
                name: t("RevPulse"),
              })}
            </Button>
          </Box>
        )}
        {props.lastActivity && !props.isPlaceholder && (
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle2">
              <strong>{t("Dashboards.Report.LastActivity")}</strong>
              {props.lastActivity}
              {props.updatedAt &&
                getRelativeTimeWithPreposition(props.updatedAt)}
            </Typography>
            <Box flex={1}></Box>
            {canViewReport &&
              props.status === FileStatus.ReportGenerated &&
              !props.isError && (
                <Button
                  variant="text"
                  color={
                    props.dashboardType === "RRI" ? "primary" : "secondary"
                  }
                  startIcon={<OpenInNewOutlined />}
                  onClick={props.onReportNameClick}
                >
                  {t("Dashboards.Report.ViewReport")}
                </Button>
              )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
