/* eslint-disable unicorn/no-empty-file */
import Typography from "@mui/material/Typography";
import { renewalWindows } from "common/constants";
import { useChartSettings, useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getContractRenewalChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { computeReportData } from "utils/chartUtils/contractRenewal";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";
import ContractRenewalDetailChart from "../charts/ContractRenewalDetailChart";
import ContractRenewalDetailTable from "../charts/ContractRenewalDetailTable";

export default function SectionContractRenewal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reportData, reportSettings } = useReportSelector();
  const chartData = useReportSelector().chartData["contract-renewal"];
  const chartSettings = useChartSettings("contract-renewal");

  const [viewToggle, setViewToggle] = useState(["chart"]);

  useEffect(() => {
    if (reportData.isLoading || !chartData.shouldFetch) return;
    dispatch(getContractRenewalChart());
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["contract-renewal"]));
  }, [
    reportSettings.params.customerLevel,
    chartSettings.renewalWindow,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  const displayableReportData = computeReportData(
    reportSettings,
    chartData.data
  );
  return (
    <>
      <Stack direction="row" alignItems="center" marginBottom={2} gap={2}>
        <Typography variant="h6" color="var(--text-secondary)">
          {t("Dashboard.Charts.SectionContractRenewal")}
        </Typography>
        <FormControl size="small" sx={{ width: "220px" }}>
          <InputLabel>{t("Dashboard.Charts.RenewalWindowSelect")}</InputLabel>
          <Select
            MenuProps={{ disableScrollLock: true }}
            label={t("Dashboard.Charts.RenewalWindow")}
            value={chartSettings.renewalWindow}
            onChange={(e) => {
              const value = e.target.value as number;
              if (value !== chartSettings.renewalWindow) {
                dispatch(
                  setChartSettings({
                    "contract-renewal": { renewalWindow: value },
                  })
                );
              }
            }}
          >
            {renewalWindows.map((x) => (
              <MenuItem key={x.key} value={x.key}>
                {x.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <GradientCard
        title={t("Dashboard.Charts.ContractRenewalDetail")}
        subheader={
          <Typography variant="body2" fontStyle="italic">
            {t("Dashboard.DollarsInThounsands")}
          </Typography>
        }
        onViewToggle={(x) => setViewToggle(x)}
        onMoreClick={() => console.log("more")}
      >
        <WrapperContainer
          isLoading={reportData.isLoading || chartData.isLoading}
          error={chartData.error || reportData.error}
          isNoData={displayableReportData.length === 0}
        >
          {viewToggle.includes("chart") && (
            <ContractRenewalDetailChart
              height={chartMinHeight}
              data={displayableReportData}
            />
          )}
          {viewToggle.includes("data") && (
            <Box sx={{ marginTop: 3, width: "100%" }}>
              <ContractRenewalDetailTable data={displayableReportData} />
            </Box>
          )}
        </WrapperContainer>
      </GradientCard>
    </>
  );
}
