import { dashboardManagerClient } from "common/client/dashboardManagerClient";
import { FileStatus } from "common/constants";
import { RowType } from "components/MappingTable";
import { FileModel } from "models/fileModel";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMatches } from "react-router-dom";
import { useFileSocket } from "utils/hook/useSocket";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { RemapModal } from "./components/RemapModal";

export function loader({
  params,
}: {
  params: { wsId: string | undefined; dashboardId: string | undefined };
}) {
  return dashboardManagerClient.get(
    `dashboard/workspaces/${params.wsId}/dashboards/${params.dashboardId}`
  );
}

type Confirmation = "yes" | "no" | "unset";
export type Mapping = { [key: string]: string };
export type RemappingInfo = {
  row: RowType;
  fieldFromFile: string;
  currentMappedTo: string;
};
export type ContextType = {
  fileStatus: FileStatus;
  fields: string[];
  errorSummary: ErrorSummary | null;
  errorMessage: string | null;
  addableFields: { [key: string]: RowType[] };
  setAddableFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: RowType[];
    }>
  >;
  doMapField: (attr: string, val: string) => void;
  mappingOrigin: Mapping;
  mappingResult: Mapping;
  confirms: { [key: string]: Confirmation };
  remappingInfo: RemappingInfo;
  setRemappingInfo: React.Dispatch<React.SetStateAction<RemappingInfo>>;
  setConfirmQuestion: (attrPrefix: string, value: Confirmation) => void;
  setOpenModal: (
    open: boolean,
    payload: RemappingInfo,
    closeSelect: () => void
  ) => void;
  closeSelectFunc: () => void;
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>;
  setMappingResult: (mapping: Mapping) => void;
};

type ErrorSummary = {
  emptyColumns: string[];
  nullColumns: string[];
  incorrectColumns: string[];
};

const confirmationPaths = {
  CustomerSegment: "customer-confirm",
  Product: "customer-segment-confirm",
  Location: "customer-values-dates-misc-confirm",
};

const MappingContext = createContext<ContextType>({
  fileStatus: FileStatus.Init,
  fields: [],
  errorSummary: null,
  errorMessage: null,
  addableFields: {},
  setAddableFields: () => {},
  doMapField: () => {},
  mappingOrigin: {},
  mappingResult: {},
  remappingInfo: {
    row: { attribute: "attr", example: "example", dataType: "dataType" },
    currentMappedTo: "to",
    fieldFromFile: "file",
  },
  confirms: {},
  setConfirmQuestion: () => {},
  setOpenModal: () => {},
  setRemappingInfo: () => {
    return {
      row: { attribute: "attr", example: "example", dataType: "dataType" },
      currentMappedTo: "to",
      fieldFromFile: "file",
    };
  },
  closeSelectFunc: () => {},
  setFileStatus: () => {},
  setMappingResult: () => {},
});

function getAddableFields(
  mappings: {
    mdtName: string;
    sourceName: string;
  }[],
  attrPrefix: string,
  t: TFunction,
  example: string
): RowType[] {
  const rows: RowType[] = [];
  const filteredMappings = mappings
    .filter((m) => m.mdtName.startsWith(attrPrefix))
    .sort((a, b) =>
      a.mdtName.localeCompare(b.mdtName, undefined, { numeric: true })
    );
  for (const [index, mapping] of filteredMappings.entries()) {
    // addable fields start from 2 (index = 1)
    if (mapping.sourceName && index !== 0) {
      rows.push({
        attribute: mapping.mdtName,
        attributeLabel: `${t(attrPrefix)} ${index + 1}`,
        example,
        dataType: t("workspace.dataMapping.dataType.string"),
      });
    }
  }
  return rows;
}

export function DataMappingContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const [addableFields, setAddableFields] = useState<{
    [key: string]: RowType[];
  }>({});
  const [mappingOrigin, setMappingOrigin] = useState<Mapping>({});
  const [mappingResult, setMappingResult] = useState<Mapping>({});

  const [remappingInfo, setRemappingInfo] = useState<RemappingInfo>({
    row: {
      isRequiredIndex: true,
      isRequired: true,
      attribute: "Customer",
      attributeLabel: "workspace.dataMapping.fields.customer",
      example: "12345678, McDonald’s",
      dataType: "workspace.dataMapping.dataType.string",
    },
    currentMappedTo: "Customer",
    fieldFromFile: "Customer",
  });
  const [confirms, setConfirms] = useState<{ [key: string]: Confirmation }>({
    [confirmationPaths.CustomerSegment]: "unset",
    [confirmationPaths.Product]: "unset",
    [confirmationPaths.Location]: "unset",
  });
  const [fields, setFields] = useState<string[]>(["Loading..."]);
  const [errorSummary, setErrorSummary] = useState<ErrorSummary | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.Init);
  const [openModal, setOpenModalState] = useState(false);
  const closeSelectFunc = useRef(() => {});
  const context: ContextType = {
    fileStatus,
    fields,
    errorSummary,
    errorMessage,
    addableFields,
    setAddableFields,
    mappingOrigin,
    mappingResult,
    confirms,
    remappingInfo,
    setConfirmQuestion: (path: string, value: Confirmation) => {
      setConfirms((prev) => ({ ...prev, [path]: value }));
    },
    doMapField: (attr: string, val: string) => {
      setMappingResult((prev) => ({ ...prev, [attr]: val }));
    },
    setOpenModal: (
      open: boolean,
      payload: RemappingInfo,
      closeSelect: () => void
    ) => {
      setOpenModalState(open);
      setRemappingInfo(payload);
      closeSelectFunc.current = closeSelect;
    },
    setRemappingInfo,
    closeSelectFunc: closeSelectFunc.current,
    setFileStatus,
    setMappingResult,
  };
  const match = useMatches();

  async function loadFileMetas() {
    const response = await loader({
      params: {
        wsId: match[1].params.wsId,
        dashboardId: match[1].params.dashboardId,
      },
    });
    setFields(response.data.data.metadata.columnHeaders);
    setErrorSummary(response.data.data.errorSummary);
    setErrorMessage(response.data.data.message);
    if (response.data.data.mapping) {
      const mappingObj = Object.fromEntries(
        (
          response.data.data.mapping as {
            mdtName: string;
            sourceName: string;
          }[]
        )
          .filter((item) => item.sourceName)
          .map((item) => [item.mdtName, item.sourceName])
      );
      setMappingOrigin(mappingObj);
      setMappingResult(mappingObj);

      const customerSegmentRows = getAddableFields(
        response.data.data.mapping,
        "CustomerSegment",
        t,
        t("workspace.dataMapping.example.customerSegment")
      );
      const productAndLocationRows = getAddableFields(
        response.data.data.mapping,
        "Product",
        t,
        t("workspace.dataMapping.example.product")
      );
      const productRows = productAndLocationRows.filter(
        (r) => r.attribute !== "Product11" && r.attribute !== "Product12"
      );
      const locationRows = productAndLocationRows.filter(
        (r) => r.attribute === "Product11" || r.attribute === "Product12"
      );

      setAddableFields({
        ...addableFields,
        CustomerSegment: customerSegmentRows,
        Product: productRows,
      });

      if (response.data.data.status >= FileStatus.DataValidated) {
        setConfirms({
          ...confirms,
          [confirmationPaths.CustomerSegment]:
            customerSegmentRows.length > 0 ? "yes" : "no",
          [confirmationPaths.Product]: productRows.length > 0 ? "yes" : "no",
          [confirmationPaths.Location]: locationRows.length > 0 ? "yes" : "no",
        });
      }
    }
    setFileStatus(response.data.data.status);
  }

  useEffect(() => {
    if (match[1].params.dashboardId) {
      loadFileMetas();
    }
  }, [match[1].params.dashboardId]);

  useFileSocket<FileModel>((payload) => {
    if (
      match[1].params.dashboardId === payload.data.dashboardId &&
      payload.data.status >= FileStatus.DataValidated
    ) {
      setFileStatus(payload.data.status);
      setErrorSummary(payload.data.errorSummary);
      setErrorMessage(payload.data.message);
      const mappingObj = Object.fromEntries(
        (payload.data.mapping ?? [])
          .filter((item) => item.sourceName)
          .map((item) => [item.mdtName, item.sourceName!])
      );
      setMappingOrigin(mappingObj);
      setMappingResult(mappingObj);
    }
  });

  return (
    <MappingContext.Provider
      value={{
        ...context,
      }}
    >
      <RemapModal setOpenModal={setOpenModalState} open={openModal} />
      {children}
    </MappingContext.Provider>
  );
}

export function useDataMappingCtx() {
  return useContext(MappingContext);
}
