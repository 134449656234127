import {
  createSlice,
  ActionReducerMapBuilder,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ExportStatus } from "models/report";
import { getReportExportProcess } from "services/reportService";
import error from "utils/error";

interface DashBoardExportData {
  isExporting: boolean;
  error: string;
  currentDownloadLink: string;
  exportId: string;
}

export interface ExcelExportState {
  [dashboardId: string]: DashBoardExportData;
}

export const initialState: ExcelExportState = {};
export const defaultDashboardExportState: DashBoardExportData = {
  isExporting: false,
  error: "",
  currentDownloadLink: "",
  exportId: "",
};

function handleGetReportProgress(
  builder: ActionReducerMapBuilder<ExcelExportState>
) {
  builder
    .addCase(getReportExportProcess.pending, (state, action) => {
      const dashboardId = action.meta.arg.dashboardId;

      if (state[dashboardId]) {
        const dashboardData = state[dashboardId];

        dashboardData.isExporting = true;
      } else {
        state[dashboardId] = {
          ...defaultDashboardExportState,
          isExporting: true,
        };
      }
    })
    .addCase(getReportExportProcess.fulfilled, (state, action) => {
      const { exportId, status, downloadUrl } = action.payload;
      const dashboardId = action.meta.arg.dashboardId;

      state[dashboardId] = {
        ...state[dashboardId],
        isExporting:
          status === ExportStatus.InProgress ||
          status === ExportStatus.RefreshingPowerBi,
        currentDownloadLink:
          status === ExportStatus.Completed ? downloadUrl || "" : "",
        exportId,
        error: "",
      };
    })
    .addCase(getReportExportProcess.rejected, (state, action) => {
      const dashboardId = action.meta.arg.dashboardId;
      const dashboardData = state[dashboardId];

      dashboardData.isExporting = false;
      dashboardData.error = error(action.error);
    });
}

const slice = createSlice({
  name: "excelExport",
  initialState,
  reducers: {
    setExportState(
      state,
      action: PayloadAction<{
        dashboardId: string;
        data: Partial<DashBoardExportData>;
      }>
    ) {
      if (state[action.payload.dashboardId]) {
        state[action.payload.dashboardId] = {
          ...state[action.payload.dashboardId],
          ...action.payload.data,
        };
      }
    },
  },
  extraReducers: (builder) => {
    handleGetReportProgress(builder);
  },
});

export const { setExportState } = slice.actions;
export default slice.reducer;
