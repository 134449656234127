import { Typography } from "@mui/material";

export default function HorizontalPercentageBarChart({
  value,
  color,
}: {
  value: number;
  color: string;
}) {
  const upperPercentage = Math.ceil(value / 100) * 100;
  return (
    <>
      {value <= 100 ? (
        <>
          <div
            style={{
              borderLeft: "1px dashed var(--neutral-05)",
              height: "64px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            <div
              style={{
                height: "28px",
                backgroundColor: color,
                width: `calc(${value}% - 2px)`,
                display: "flex",
                alignItems: "center",
                paddingLeft: "4px",
                paddingTop: "2px",
                borderRadius: "0px 4px 4px 0px",
              }}
            >
              <Typography variant="caption" color="white">
                {value}%
              </Typography>
            </div>
            <div
              style={{
                height: "51px",
                backgroundColor: "var(--neutral-08)",
                width: "2px",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "2px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "40%",
              }}
            >
              <Typography variant="caption" color="var(--neutral-06)">
                0%
              </Typography>
            </div>
            <div
              style={{
                width: "calc(100% - 40%)",
                textAlign: "right",
              }}
            >
              <Typography variant="caption" color="var(--neutral-06)">
                100%
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              borderLeft: "1px dashed var(--neutral-05)",
              height: "64px",
              display: "flex",
              gap: "2px",
              alignItems: "center",
              paddingRight: "20px",
            }}
          >
            <div
              style={{
                height: "28px",
                backgroundColor: color,
                width: "calc(50% - 4px)",
                display: "flex",
                alignItems: "center",
                paddingLeft: "4px",
                paddingTop: "2px",
              }}
            >
              <Typography variant="caption" color="white">
                {value}%
              </Typography>
            </div>
            <div
              style={{
                height: "51px",
                backgroundColor: "var(--neutral-08)",
                width: "2px",
              }}
            ></div>
            <div
              style={{
                height: "28px",
                width: `calc(${(value / upperPercentage) * 100 - 50}% - 2px)`,
                backgroundColor: color,
                borderRadius: "0px 4px 4px 0px",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "2px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "calc(50% - 4px)",
              }}
            >
              <Typography variant="caption" color="var(--neutral-06)">
                0%
              </Typography>
            </div>
            <div
              style={{
                width: "2px",
                position: "relative",
              }}
            >
              <Typography
                variant="caption"
                color="var(--neutral-06)"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "calc(50% - 6px)",
                  transform: "translate(-50%, -50%)",
                }}
              >
                100%
              </Typography>
            </div>
            <div
              style={{
                textAlign: "right",
                flexGrow: 1,
              }}
            >
              <Typography variant="caption" color="var(--neutral-06)">
                {upperPercentage}%
              </Typography>
            </div>
          </div>
        </>
      )}
    </>
  );
}
