import {
  ArrowForward,
  LogoutOutlined,
  LooksOneOutlined,
  LoopOutlined,
  MoreHoriz,
  UTurnLeftOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Menu,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { dashboardManagerClient } from "common/client/dashboardManagerClient";
import { FileStatus, ROUTE_PATHS } from "common/constants";
import { MappingSelect, RowType } from "components/MappingTable";
import { StyledMenuItem } from "containers/Workspace/Manage/components/StyledMenuItem";
import { WORKSPACE_ROUTES, buildReviewRows } from "containers/Workspace/config";
import _ from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { PopupState as PopupStateType } from "material-ui-popup-state/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useToast } from "utils/hook/useNotification";

import { useDataMappingCtx } from "../DataMappingContext";

import SubmitConfirmModal from "./AnalysisConfirmModal";
import { ErrorTooltip } from "./ErrorTooltip";
import Loading from "./Loading";
import MappingLayout from "./MappingLayout";
import {
  ErrorsType,
  buildPayload,
  getErrorType,
  validateMapping,
} from "./Review.utils";

export default function Review() {
  const { t } = useTranslation();
  const { showError } = useToast();
  const [validating, setValidating] = useState(false);
  const [submiting, setSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const location = useLocation();
  const backRoute = `../${WORKSPACE_ROUTES.CUSTOMER_DATA_ADDITIONAL}`;
  const {
    fileStatus,
    setFileStatus,
    fields,
    errorSummary,
    errorMessage,
    mappingOrigin,
    mappingResult,
    doMapField,
  } = useDataMappingCtx();
  const rows = buildReviewRows(mappingResult);
  const params = useParams();
  const hasAPIErrors =
    !!errorMessage ||
    (!!errorSummary &&
      Object.values(errorSummary).some((item) => item.length > 0));

  const isMappingChanged = useMemo(() => {
    return !_.isEqual(mappingOrigin, mappingResult);
  }, [mappingOrigin, mappingResult]);

  const isSubmitted =
    fileStatus >= FileStatus.DataValidated && !isMappingChanged;

  let allErrors = {};
  if (errorSummary) {
    allErrors = { ...allErrors, ...errorSummary };
  }

  if (fetcher.data && fetcher.data.data && fetcher.data.hasError) {
    allErrors = { ...allErrors, ...fetcher.data.data };
  }

  useEffect(() => {
    if (fetcher.data && fetcher.data.data && fetcher.data.hasError) {
      setValidating(false);
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (fileStatus >= FileStatus.DataValidated) {
      setValidating(false);
    }
    if (fileStatus === FileStatus.ReportGenerated) {
      setSubmitting(false);
    }
  }, [fileStatus]);

  useEffect(() => {
    if (fetcher.data && !fetcher.data.ok) {
      showError({ message: fetcher.data.errorMessage });
    }
  }, fetcher.data);

  const getError = useCallback(
    (row: RowType) => {
      return getErrorType(row, allErrors as ErrorsType, mappingResult);
    },
    [mappingResult, allErrors]
  );

  function handleOnMappingChanged(attribute: string, value: string) {
    if (fetcher.data && fetcher.data.data) {
      fetcher.data.hasError = false;
      fetcher.data.data = null;
    }
    doMapField(attribute, value);
  }

  if (
    fileStatus === FileStatus.ReportGenerated &&
    !errorSummary &&
    !errorMessage
  ) {
    return (
      <Navigate
        to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${ROUTE_PATHS.WORKSPACES_REPORT}`}
      />
    );
  }

  if (validating || submiting) {
    return <Loading variant={submiting ? "analyze" : "validate"} />;
  }

  return (
    <MappingLayout>
      <SubmitConfirmModal
        open={openModal}
        setOpenModal={setOpenModal}
        onSubmit={() => {
          setSubmitting(true);
          fetcher.submit(null, {
            action: `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`,
            method: "post",
          });
        }}
      />
      <Container>
        <Card elevation={0}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                textTransform="uppercase"
                color="text.primary"
              >
                {t("MappingReview.ReviewPageHeader")}
              </Typography>
            }
          />
          <CardHeader
            title={
              <Typography variant="h4" color="text.primary">
                {t("MappingReview.ReviewPageHeader2")}
              </Typography>
            }
          />
          <Box
            sx={{
              pl: "16px",
            }}
          >
            {fileStatus === FileStatus.Init && (
              <Typography variant="h5">
                {t("MappingReview.ReviewSubHeader")}
              </Typography>
            )}
            {fileStatus === FileStatus.DataValidated &&
              !hasAPIErrors &&
              !isMappingChanged && (
                <Alert severity="success">
                  <Typography color="#2E7D57">
                    {t("MappingReview.ValidatedAlertMsg")}
                  </Typography>
                </Alert>
              )}
            {((fetcher.data && fetcher.data.hasError) ||
              (fileStatus === FileStatus.DataValidated &&
                hasAPIErrors &&
                !isMappingChanged)) && (
              <Alert
                severity="error"
                sx={{
                  backgroundColor: "#FDEDED",
                }}
              >
                <Typography
                  sx={{
                    color: "#5F2120",
                  }}
                >
                  {t("MappingReview.ValidationErrorMsg")}
                </Typography>
              </Alert>
            )}
            {fileStatus >= FileStatus.DataSubmitted && (
              <Alert
                security="info"
                color="info"
                action={
                  <Button
                    onClick={() =>
                      navigate(
                        `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
                      )
                    }
                    sx={{ color: "#014361" }}
                  >
                    {t("MappingReview.ToWorkspacesAction")}
                  </Button>
                }
              >
                <AlertTitle>{t("MappingReview.AnalyzingHeader")}</AlertTitle>
                {t("MappingReview.AnalyzingDescription")}
              </Alert>
            )}
          </Box>
          <CardContent>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {isSubmitted && (
                      <TableCell
                        sx={{
                          border: "none",
                          width: "15px",
                        }}
                      />
                    )}
                    <TableCell>
                      {t("workspace.dataMapping.mappingTableFields.attribute")}
                    </TableCell>
                    <TableCell>
                      {t(
                        "workspace.dataMapping.mappingTableFields.sourceDataField"
                      )}
                    </TableCell>
                    <TableCell>
                      {t("workspace.dataMapping.mappingTableFields.example")}
                    </TableCell>
                    <TableCell>
                      {t("workspace.dataMapping.mappingTableFields.dataType")}
                    </TableCell>
                    <TableCell sx={{ width: "30px", border: "none" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <>
                      {row.sectionName && (
                        <TableRow
                          key={row.sectionName}
                          sx={{
                            width: "100%",
                          }}
                        >
                          {isSubmitted && <TableCell sx={{ border: "none" }} />}
                          <TableCell
                            sx={{
                              padding: 0,
                              border: "none",
                            }}
                          >
                            {row.sectionName && (
                              <CardHeader
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  paddingLeft: "16px",
                                  height: "24px",
                                }}
                                title={
                                  <Typography
                                    variant="overline"
                                    color="primary.dark"
                                    textTransform="uppercase"
                                  >
                                    {row.sectionName}
                                  </Typography>
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                            }}
                          />
                          <TableCell
                            sx={{
                              border: "none",
                            }}
                          />
                          <TableCell
                            sx={{
                              border: "none",
                            }}
                          />
                        </TableRow>
                      )}
                      <TableRow
                        key={row.attribute}
                        sx={{
                          backgroundColor:
                            getError(row).errorCode > 0
                              ? "rgba(211, 47, 47, 0.04)"
                              : "unset",
                        }}
                      >
                        {isSubmitted && (
                          <TableCell
                            sx={{
                              border: "none",
                              width: "fit-content",
                              px: 0,
                              pr: "8px",
                              backgroundColor: "#FFF",
                            }}
                          >
                            <ErrorTooltip errorType={getError(row)} row={row} />
                          </TableCell>
                        )}
                        <TableCell>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="body2" color="text.secondary">
                              {t(row.attributeLabel || "attribute") ||
                                row.attribute}
                            </Typography>
                            <ArrowForward
                              sx={{
                                color: "action.active",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            sx={{ minWidth: 250 }}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={2}
                          >
                            <MappingSelect
                              invalid={getError(row).errorCode > 0}
                              sourceFields={fields}
                              mappingValues={mappingResult}
                              onChange={handleOnMappingChanged}
                              row={row}
                            />
                            <ArrowForward
                              sx={{
                                color: "action.active",
                                width: "24px",
                                height: "24px",

                                visibility:
                                  row.attribute === "FiscalStartMonth"
                                    ? "hidden"
                                    : "visible",
                              }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ maxWidth: "140px", padding: "16px" }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              visibility:
                                row.attribute === "FiscalStartMonth"
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            {row.example}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                visibility:
                                  row.attribute === "FiscalStartMonth"
                                    ? "hidden"
                                    : "visible",
                              }}
                            >
                              {t(row.dataType)}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 0,
                            backgroundColor: "#FFF",
                            border: "none",
                          }}
                        >
                          <IconButton>
                            <PopupState
                              variant="popover"
                              popupId="teams-cardview-popup-menu"
                            >
                              {(popupState: PopupStateType) => (
                                <>
                                  <MoreHoriz {...bindTrigger(popupState)} />
                                  <Menu {...bindMenu(popupState)}>
                                    <StyledMenuItem
                                      onClick={() => {
                                        row.stepURL && navigate(row.stepURL);
                                        popupState.close();
                                      }}
                                    >
                                      <UTurnLeftOutlined />{" "}
                                      {t("MappingReview.FieldMenu.ToStep")}
                                    </StyledMenuItem>
                                    <StyledMenuItem
                                      onClick={() => {
                                        navigate(
                                          `../${WORKSPACE_ROUTES.CUSTOMER_DATA}`
                                        );
                                        popupState.close();
                                      }}
                                    >
                                      <LooksOneOutlined />{" "}
                                      {t("MappingReview.FieldMenu.ToFirstStep")}
                                    </StyledMenuItem>
                                    {!row.isRequired && <Divider />}
                                    {!row.isRequired && (
                                      <StyledMenuItem
                                        onClick={() => {
                                          doMapField(row.attribute, "");
                                          popupState.close();
                                        }}
                                      >
                                        <LogoutOutlined />{" "}
                                        {t(
                                          "MappingReview.FieldMenu.RemoveAttr"
                                        )}
                                      </StyledMenuItem>
                                    )}
                                  </Menu>
                                </>
                              )}
                            </PopupState>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>
            {fileStatus >= FileStatus.DataValidated &&
            !hasAPIErrors &&
            !isMappingChanged ? (
              <Button
                variant="contained"
                disabled={fileStatus >= FileStatus.DataSubmitted}
                onClick={() => setOpenModal(true)}
              >
                {fileStatus >= FileStatus.DataSubmitted ? (
                  <Stack direction="row" gap="4px">
                    <LoopOutlined
                      sx={{
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    {t("MappingReview.AnalyzingLabel")}
                  </Stack>
                ) : (
                  <>{t("MappingReview.AnalysisBtn")}</>
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={fileStatus >= FileStatus.DataSubmitted}
                sx={{ borderRadius: "120px" }}
                onClick={() => {
                  setFileStatus(FileStatus.DataImported);
                  if (fetcher.data && fetcher.data.hasError) {
                    fetcher.data.hasError = false;
                  }
                  setValidating(true);
                  fetcher.submit(
                    {
                      data: JSON.stringify({
                        mappingObj: mappingResult,
                        columns: fields,
                      }),
                    },
                    {
                      action: `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`,
                      method: "post",
                    }
                  );
                }}
              >
                {t("MappingReview.ValidateBtn")}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{ borderRadius: "120px" }}
              onClick={() => {
                if (location.state?.from === "nextButton") {
                  navigate(-1);
                } else {
                  navigate(backRoute);
                }
              }}
            >
              {t("workspace.dataMapping.backBtn")}
            </Button>
            <Button
              sx={{ borderRadius: "120px" }}
              onClick={() =>
                navigate(
                  `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${ROUTE_PATHS.WORKSPACES_HOME}`
                )
              }
            >
              {t("workspace.dataMapping.cancelBtn")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </MappingLayout>
  );
}

export async function actions({
  params,
  request,
}: {
  params: { wsId?: string; dashboardId?: string };
  request: Request;
}) {
  switch (request.method) {
    case "POST": {
      const rawData = await request.formData();
      if (!rawData.get("data")) {
        // Analyse
        try {
          await dashboardManagerClient.post(
            `dashboard/workspaces/${params.wsId}/dashboards/${params.dashboardId}/start-analysis`
          );
          return {
            ok: true,
          };
        } catch {
          return {
            ok: false,
            errorMessage: "Something wrong...",
          };
        }
      }
      const data = JSON.parse(rawData.get("data") as string);
      const payload = buildPayload(data.mappingObj, data.columns);
      const validateResp = validateMapping(payload);
      if (
        Object.keys(validateResp.duplicatedColumns).length > 0 ||
        validateResp.requiredFields.length > 0
      ) {
        return {
          ok: true,
          hasError: true,
          data: validateResp,
        };
      }
      try {
        await dashboardManagerClient.post(
          `dashboard/workspaces/${params.wsId}/dashboards/${params.dashboardId}/mapping`,
          { mapObjects: payload }
        );
      } catch {
        return {
          ok: false,
          errorMessage: "Something wrong...",
        };
      }
      return {
        ok: true,
        data: validateResp,
      };
    }
    default: {
      return {
        ok: false,
      };
    }
  }
}
