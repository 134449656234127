import {
  Cached,
  ExpandLess,
  ExpandMore,
  TableViewOutlined,
} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { ROUTES, ReportMeasurement } from "common/constants";
import {
  useDashboardExportData,
  useDispatch,
  useReportSelector,
} from "common/store";
import { ExportResponse, ExportStatus } from "models/report";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { setExportState } from "slices/excelExportSlice";
import { useToast } from "utils/hook/useNotification";
import { useReportSocket } from "utils/hook/useSocket";

import { NAVBAR_ITEMS } from "../constants";

import ExcelExportedModal from "./ExcelExportedModal";

interface NavBarState {
  [key: string]: "collapsed" | "expanded" | "selected";
}

const defaultState: NavBarState = {};
for (const x of NAVBAR_ITEMS) {
  if (x.key) defaultState[x.key] = "collapsed";
}
defaultState.Dashboard_Revenue = "expanded";

interface Props {
  width: number;
  onItemClick: (key: string) => void;
}

export default function DashboardNavBar(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const exportData = useDashboardExportData(params.dashboardId!);
  const { reportSettings } = useReportSelector();
  const { showError } = useToast();

  const [navBar, setNavBar] = useState(defaultState);
  const [isExportedModalOpen, setIsExportedModalOpen] = useState(false);

  useReportSocket<ExportResponse>((payload) => {
    if (payload.data.status === ExportStatus.Completed) {
      dispatch(
        setExportState({
          dashboardId: params.dashboardId!,
          data: {
            currentDownloadLink: payload.data.downloadUrl || "",
            error: "",
            isExporting: false,
          },
        })
      );
    } else if (
      payload.data.status === ExportStatus.RefreshPowerBiFailed ||
      payload.data.status === ExportStatus.ExecuteSnowflakeFailed
    ) {
      dispatch(
        setExportState({
          dashboardId: params.dashboardId!,
          data: {
            error: t("Dashboard.NavBar.ExcelWorkbook.ExportError"),
            isExporting: false,
          },
        })
      );
    }
  });

  useEffect(() => {
    if (exportData.isExporting) return;

    if (exportData.error) {
      showError({ message: exportData.error });
    } else if (exportData.currentDownloadLink) {
      setIsExportedModalOpen(true);
    }
  }, [exportData.isExporting]);

  function getIcon(key: string): React.ReactElement {
    switch (navBar[key]) {
      case "selected":
      case "collapsed": {
        return <ExpandMore sx={{ color: "var(--icon-color)" }} />;
      }
      case "expanded": {
        return <ExpandLess sx={{ color: "var(--icon-color)" }} />;
      }
      default: {
        return <></>;
      }
    }
  }

  function handleOnExcelWorkbookClick() {
    navigate(
      ROUTES.REPORT_EXCEL_EXPORT(params.id!, params.wsId!, params.dashboardId!)
    );
  }

  function handleExportedModalClose() {
    dispatch(
      setExportState({
        dashboardId: params.dashboardId!,
        data: {
          currentDownloadLink: "",
        },
      })
    );
    setIsExportedModalOpen(false);
  }

  function handleOnItemClick(key: string): void {
    const status = navBar[key];
    for (const x of Object.keys(navBar)) {
      navBar[x] = "collapsed";
    }
    switch (status) {
      case "selected":
      case "collapsed": {
        setNavBar({ ...navBar, [key]: "expanded" });
        break;
      }
      case "expanded": {
        setNavBar({ ...navBar, [key]: "selected" });
        break;
      }
    }
  }

  async function handleSubItemClick(key: string) {
    const reportRoute = ROUTES.REPORT(
      params.id!,
      params.wsId!,
      params.dashboardId!
    );
    if (window.location.pathname !== reportRoute) {
      await navigate(reportRoute);
    }
    props.onItemClick(key);
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: props.width,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { boxSizing: "border-box", width: props.width },
      }}
    >
      <Toolbar />
      <List component="nav" sx={{ paddingX: 1, paddingY: 2 }}>
        {NAVBAR_ITEMS.map((x, i) => {
          return x.type === "divider" ? (
            <Divider key={i} sx={{ marginY: 3 }} />
          ) : (
            <div key={i}>
              <ListItemButton
                key={x.key}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: "#29B6F614",
                  },
                }}
                disabled={x.disabled}
                selected={navBar[x.key!] && navBar[x.key!] !== "collapsed"}
                onClick={() => handleOnItemClick(x.key!)}
              >
                <ListItemIcon>{x.icon}</ListItemIcon>
                <ListItemText primary={t(x.key!)} />
                {x.children && getIcon(x.key)}
              </ListItemButton>
              {x.children && (
                <Collapse
                  in={navBar[x.key] === "expanded"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {x.children.map((y, j) => (
                      <ListItemButton
                        onClick={() => handleSubItemClick(y.refKey)}
                        key={j}
                        dense
                        selected={navBar[x.key] === "expanded"}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: "#29B6F60A",
                          },
                        }}
                      >
                        <ListItemText
                          sx={{ paddingLeft: 7 }}
                          primary={t(y.title, {
                            view: ReportMeasurement[reportSettings.measurement],
                          })}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
        <Divider sx={{ marginY: 3 }} />
        <ListItemButton onClick={handleOnExcelWorkbookClick}>
          <ListItemIcon>
            <TableViewOutlined />
          </ListItemIcon>
          <ListItemText primary={t("Dashboard.NavBar.ExcelWorkbook.Text")} />
          {exportData.isExporting && <Cached color="primary" />}
        </ListItemButton>
      </List>
      <ExcelExportedModal
        open={isExportedModalOpen}
        dashboardId={params.dashboardId!}
        onClose={handleExportedModalClose}
      />
    </Drawer>
  );
}
