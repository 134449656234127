import { CustomerByChangeCategoryChartModel } from "models/report";
import { CustomerLevel } from "common/constants";

type CountChangeChartBar = keyof Omit<
  CustomerByChangeCategoryChartModel,
  "xValue" | "year" | "Ending Count"
>;

export const CUSTOMER_LEVEL_CHART_BARS: CountChangeChartBar[] = [
  "Beginning Count",
  "New Customer",
  "Customer Reactivation",
  "Lost Customer",
];

export const CUSTOMER_PRODUCT_LEVEL_CHART_BARS: CountChangeChartBar[] = [
  "Beginning Count",
  "New Customer",
  "Customer Reactivation",
  "Cross-Sell",
  "Product Reactivation",
  "Lost Product",
  "Lost Customer",
];

export const CATEGORIES_BY_CUSTOMER_LEVEL = {
  [CustomerLevel.Customer]: CUSTOMER_LEVEL_CHART_BARS,
  [CustomerLevel.CustomerProduct]: CUSTOMER_PRODUCT_LEVEL_CHART_BARS,
};

export const CHART_BARS = {
  "Beginning Count": {
    key: "Beginning Count",
    color: "var(--color-positive-02)",
  },
  "New Customer": {
    key: "New Customer",
    color: "var(--color-positive-09)",
  },
  "Customer Reactivation": {
    key: "Customer Reactivation",
    color: "var(--indigo-02)",
  },
  "Lost Customer": {
    key: "Lost Customer",
    color: "var(--color-negative-05)",
  },
  "Cross-Sell": {
    key: "Cross-Sell",
    color: "var(--color-positive-22)",
  },
  "Lost Product": {
    key: "Lost Product",
    color: "var(--color-negative-09)",
  },
  "Product Reactivation": {
    key: "Product Reactivation",
    color: "var(--color-positive-16)",
  },
} as const;
