import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "common/constants";

import { Mapping, useDataMappingCtx } from "../DataMappingContext";

import MappingLayout from "./MappingLayout";

export type ConfirmProps = {
  title: string;
  questions: string[];
  yesRoute: string;
  noRoute: string;
  backRoute?: string;
  attrFilter?: (attr: string) => boolean;
};

function filterOutMappings(
  mapping: Mapping,
  shouldFilterOut?: (attr: string) => boolean
): Mapping {
  const newMapping: Mapping = {};

  for (const attr in mapping) {
    if (shouldFilterOut && !shouldFilterOut(attr)) {
      newMapping[attr] = mapping[attr];
    }
  }

  return newMapping;
}

export default function ConfirmDialog({
  title,
  questions,
  yesRoute,
  noRoute,
  backRoute,
  attrFilter,
}: ConfirmProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const path = location.pathname.split("/").at(-1) || "__none__";
  const {
    confirms,
    setConfirmQuestion: setConfirms,
    mappingResult,
    setMappingResult,
  } = useDataMappingCtx();

  return (
    <MappingLayout>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          elevation={0}
          sx={{
            width: "731px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "23px",
          }}
        >
          <CardHeader
            title={<Typography variant="h3">{t(title)}</Typography>}
          />
          <CardContent
            sx={{
              display: "flex",
              padding: "23px",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "stretch",
            }}
          >
            <FormControl>
              <RadioGroup
                sx={{ display: "flex", flexDirection: "column", gap: "23px" }}
                value={confirms[path]}
                onChange={(event) =>
                  setConfirms(path, event.target.value as "yes" | "no")
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  value="yes"
                  label={
                    <Typography variant="h5">{t(questions[0])}</Typography>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value="no"
                  label={
                    <Typography variant="h5">{t(questions[1])}</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              disabled={!confirms[path]}
              sx={{ borderRadius: "119px" }}
              onClick={() => {
                const selectedYes = confirms[path] === "yes";

                if (!selectedYes) {
                  const newMapping = filterOutMappings(
                    mappingResult,
                    attrFilter
                  );
                  setMappingResult(newMapping);
                }

                selectedYes
                  ? navigate(yesRoute, { state: { from: "nextButton" } })
                  : navigate(noRoute, { state: { from: "nextButton" } });
              }}
            >
              {t("workspace.dataMapping.continueBtn")}
            </Button>
            <Button
              variant="outlined"
              sx={{ borderRadius: "119px" }}
              onClick={() => {
                if (location.state?.from === "nextButton") {
                  navigate(-1);
                } else {
                  backRoute && navigate(backRoute);
                }
              }}
            >
              {t("workspace.dataMapping.backBtn")}
            </Button>
            <Button
              sx={{ borderRadius: "119px" }}
              onClick={() =>
                navigate(
                  `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${ROUTE_PATHS.WORKSPACES_HOME}`
                )
              }
            >
              {t("workspace.dataMapping.cancelBtn")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </MappingLayout>
  );
}
