import { AnalysisType, ReportMeasurement } from "common/constants";

export interface RevenueMeasurements {
  SumArrAmount: number | null;
  SumMrrAmount: number | null;
  SumCArrAmount: number | null;
  SumCMrrAmount: number | null;
}

export interface FiscalMonth {
  Month: string;
  DISPLAYQTR: string;
  FISCALYR: string;
}

export interface OverviewChartData extends FiscalMonth {
  SelectedSegmentation?: string;
  ArrEndingBalance: number;
  ArrGrossRetention: number;
  ArrNetRetention: number;
  ArrGrowthRate: number;
  CArrEndingBalance: number;
  CArrGrossRetention: number;
  CArrNetRetention: number;
  CArrGrowthRate: number;
}

export type OverviewChartModel = {
  month: string;
  xValue: string;
  index: number;
} & {
  [key: string]: number;
};

export interface RevenueByCustomer extends RevenueMeasurements {
  Customer: string;
  Month: string;
  ProductValue?: string;
  CustomerValue?: string;
}

export type ByValue = "base" | "churn-only" | "gross" | "net" | "customer";
export type Measurement = "arr" | "mrr" | "carr" | "cmrr";
export type Axis = "cohort-tenure" | "calendar-period";
export type Show = Measurement | "count" | "volume";
export type RelativeTo = "initial" | "prior";

export interface SegCustResponse {
  TYPE: string;
  CUSTOMERSEGMENTKEY: number;
  VALUE: string;
}

export interface SegProdResponse {
  TYPE: string;
  PRODUCTKEY: number;
  VALUE: string;
}

export type RevenueByCustomerChartModel = {
  customer: string;
  month: string;
} & {
  [key: string]: number;
};

export interface CustomerResponse {
  customerkey: number;
  customer: string;
}

type CrossSellKeys = `CROSS-SELLPRODUCT${number}`;
type LostProductKeys = `LOST-PRODUCTPRODUCT${number}`;
type ProductReactivationKeys = `PRODUCT REACTIVATIONPRODUCT${number}`;

export interface RevenueByChangeCategoryResponse {
  Month: string;
  FISCALYR: string;
  DISPLAYQTR: string;
  BeginningBalance: RevenueMeasurements;
  "NEW CUSTOMER"?: RevenueMeasurements;
  "CUSTOMER REACTIVATION"?: RevenueMeasurements;
  UPSELL?: RevenueMeasurements;
  DOWNSELL?: RevenueMeasurements;
  "LOST CUSTOMER"?: RevenueMeasurements;
  "BEGINNING/ENDING REVENUE CREDITS"?: RevenueMeasurements;
  "CUSTOMER SEGMENT MIGRATION"?: RevenueMeasurements;
  EndingBalance: RevenueMeasurements;
  [keys: CrossSellKeys]: RevenueMeasurements;
  [keys: LostProductKeys]: RevenueMeasurements;
  [keys: ProductReactivationKeys]: RevenueMeasurements;
}
export interface RevenueByChangeCategoryVolumeImpactResponse {
  Month: string;
  DISPLAYQTR: string;
  FISCALYR: string;
  IsLastMonthOfQuarter: boolean;
  IsLastMonthOfYear: boolean;
  UPSELL?: {
    [key: string]: number;
  };
  DOWNSELL?: {
    [key: string]: number;
  };
}

export interface RevenueByChangeCategoryChartModel {
  xValue: string;
  "Beginning Balance": number;
  "New Customer"?: number;
  "Customer Reactivation"?: number;
  Upsell?: number;
  Downsell?: number;
  "Lost Customer"?: number;
  "Ending/Beginning Revenue Credits"?: number;
  "Cust Segment Migration"?: number;
  "Ending Balance": number;
  // Additional categories of customer-product level
  "Cross-Sell"?: number;
  "Lost Product"?: number;
  "Product Reactivation"?: number;
  year: string;
  month: string;
}

export type CategoriesAndPeriodsTableModel = {
  category: string;
} & { [periods: string]: number };

export interface CustomersByChangeCategoryResponse {
  Month: string;
  FISCALYR: string;
  DISPLAYQTR: string;
  StartingCustomers: RevenueMeasurements;
  "NEW CUSTOMER"?: RevenueMeasurements;
  "CUSTOMER REACTIVATION"?: RevenueMeasurements;
  "LOST CUSTOMER"?: RevenueMeasurements;
  EndingCustomers: RevenueMeasurements;
}

export interface ContractRenewalCategoryResponse {
  EXPIRINGENDDATE?: string;
  Month: string;
  DISPLAYQTR: string;
  FISCALYR: string;
  EXPIRINGACV?: number;
  UPSELLACV?: number;
  DOWNSELLACV?: number;
  LOSTACV?: number;
}

export interface ARRChangeOverviewCategoryModel {
  xValue: string;
  "Beginning Balance"?: number;
  "New Customer"?: number;
  Upsell?: number;
  Reactivation?: number;
  Downsell?: number;
  "Lost Customer"?: number;
  Total?: number;
}

export interface ContractRenewalCategoryModel {
  xValue: string;
  "Expiring ACV"?: number;
  "Lost ACV"?: number;
  "Upsell ACV"?: number;
  "Downsell ACV"?: number;
}

export interface CustomerByChangeCategoryChartModel {
  xValue: string;
  year: string;
  "Beginning Count": number;
  "New Customer"?: number;
  "Customer Reactivation"?: number;
  "Lost Customer"?: number;
  "Ending Count": number;
  // Additional categories of customer-product level
  "Cross-Sell"?: number;
  "Lost Product"?: number;
  "Product Reactivation"?: number;
}

export interface CohortReport {
  Month: string;
  FISCALYR: string;
  DISPLAYQTR: string;
  COHORT_DISPLAYQTR: string;
  COHORT_FISCALYR: string;
  CUSTOMER_COUNT?: number;
  SUM_ENDING_BALANCE?: number | null;
  SUM_UPSELL_PRIOR?: number | null;
  SUM_DOWNSELL_PRIOR?: number | null;
  SUM_LOST_CUSTOMER_PRIOR?: number | null;
  SUM_BEGINNING_BALANCE_PRIOR?: number | null;
  SUM_UPSELL_INITIAL?: number | null;
  SUM_DOWNSELL_INITIAL?: number | null;
  SUM_LOST_CUSTOMER_INITIAL?: number | null;
  SUM_BEGINNING_BALANCE_INITIAL?: number | null;
  INITIAL_CUSTOMER?: number | null;
  INITIAL_LOST_CUSTOMER?: number | null;
  PRIOR_CUSTOMER?: number | null;
  PRIOR_LOST_CUSTOMER?: number | null;
  retentionValue?: number | string | null;
}

export interface ARRCohortReport extends CohortReport {
  ARRSTARTDATE: string;
  ARRTENURE: number;
  ARRTENUREQTR: number;
  ARRTENUREYR: number;
  SumArrAmount: number;
  SumMrrAmount: number;
}

export interface CARRCohortReport extends CohortReport {
  CARRSTARTDATE: string;
  CARRTENURE: number;
  CARRTENUREQTR: number;
  CARRTENUREYR: number;
  SumCArrAmount: number;
  SumCMrrAmount: number;
}

export interface RevenueChangeDrilldownResponse {
  Month: string;
  Category: string;
  DISPLAYQTR: string;
  FISCALYR: string;
  CUSTOMERKEY: number;
  ARR_AMOUNT?: number;
  CARR_AMOUNT?: number;
  MOMARRAMOUNT?: number;
  QOQARRAMOUNT?: number;
  YOYARRAMOUNT?: number;
  MOMCARRAMOUNT?: number;
  QOQCARRAMOUNT?: number;
  YOYCARRAMOUNT?: number;
  Diff: number;
  "Beginning Balance SUM": number;
  "Ending Balance SUM": number;
  Name: string;
  "Total Diff": number;
  COUNT: number;
}

export enum ExportStatus {
  InProgress,
  ExecuteSnowflakeFailed,
  RefreshingPowerBi,
  RefreshPowerBiFailed,
  Completed,
}

export interface ExportResponse {
  exportId: string;
  downloadUrl: string;
  status: ExportStatus;
}

export interface ReportFile {
  id: string;
  fileId: string;
  threshold: number;
  period: AnalysisType;
  type: ReportMeasurement;
  status: ExportStatus;
  blobFileName: string;
  createdAt: string;
  downloadUrl?: string;
}
