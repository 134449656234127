import GradientCard from "components/Card/GradientCard";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import HorizontalPercentageBarChart from "../../../components/Charts/HorizontalPercentageBarChart";
export default function SectionARROverview() {
  const { t } = useTranslation();

  return (
    <>
      <GradientCard
        title={t("Dashboard.Charts.ARROverview")}
        subheader={t("Dashboard.Charts.ARROverviewSubheader")}
        onExport={() => console.log("export")}
      >
        <div
          style={{
            columnCount: 3,
            columnRuleStyle: "dashed",
            columnRuleWidth: "1px",
            columnRuleColor: "var(--neutral-05)",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              breakInside: "avoid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Typography variant="body1" color="var(--neutral-07)">
                Ending ARR
              </Typography>
              <Typography
                variant="h5"
                color="var(--neutral-09)"
                fontWeight={600}
              >
                $115M
              </Typography>
            </div>
          </Stack>
          <Stack
            sx={{
              breakInside: "avoid",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography variant="body1" color="var(--neutral-07)">
                Growth Rate (%)
              </Typography>
              <Typography
                variant="h5"
                color="var(--neutral-09)"
                fontWeight={600}
              >
                26.3%
              </Typography>
            </div>
          </Stack>
          <Stack
            sx={{
              breakInside: "avoid",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography variant="body1" color="var(--neutral-07)">
                Full Churn (%)
              </Typography>
              <Typography
                variant="h5"
                color="var(--neutral-09)"
                fontWeight={600}
              >
                (12.4%)
              </Typography>
            </div>
          </Stack>
        </div>
        <hr
          style={{
            borderTop: "1px dashed var(--neutral-05)",
            marginTop: "20px",
            height: "1px",
          }}
        />
        <Typography
          variant="body1"
          color="var(--neutral-09)"
          sx={{ marginBottom: "5px", marginTop: "20px" }}
        >
          Gross Retention (%)
        </Typography>
        <HorizontalPercentageBarChart
          value={50}
          color="var(--color-positive-02)"
        />
        <hr
          style={{
            borderTop: "1px dashed var(--neutral-05)",
            marginTop: "20px",
            height: "1px",
          }}
        />
        <Typography
          variant="body1"
          color="var(--neutral-09)"
          sx={{ marginBottom: "5px", marginTop: "20px" }}
        >
          Net Retention (%)
        </Typography>
        <HorizontalPercentageBarChart
          value={150}
          color="var(--color-positive-09)"
        />
      </GradientCard>
    </>
  );
}
