import LineBarComposedChart from "components/Charts/LineBarComposedChart";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";
import { SingleValueTooltip } from "components/ChartTooltip";
import { CustomerByChangeCategoryChartModel } from "models/report";
import { getCustomerCountValue, getPeriodAxisLabel } from "utils/format";
import { useTranslation } from "react-i18next";
import { getChartBars } from "utils/chartUtils/customersByChangeCategory";
import { useReportSelector } from "common/store";

import CustomYAxisTick from "../CustomYAxisTick";

interface Props {
  height: string | number;
  data: CustomerByChangeCategoryChartModel[];
}

export default function CountChangesChart(props: Props) {
  const { t } = useTranslation();
  const { reportSettings } = useReportSelector();

  return (
    <LineBarComposedChart
      xAxisKey="xValue"
      bars={getChartBars(reportSettings.params.customerLevel)}
      line={{ key: "Ending Count" }}
      data={props.data}
      height={props.height}
      width="100%"
      xAxisProps={{
        interval: 0,
        tick: ({ payload, ...remains }) => (
          <VerticalAxisTick
            value={getPeriodAxisLabel(
              payload.value,
              props.data.map((x) => x.xValue)
            )}
            {...remains}
          />
        ),
      }}
      yAxisProps={{
        tick: ({ payload, ...remains }) => (
          <CustomYAxisTick
            value={getCustomerCountValue(payload.value)}
            isRoot={payload.value === 0}
            {...remains}
          />
        ),
      }}
      margin={{ top: 0, left: 24, right: 24, bottom: 16 }}
      renderTooltip={(payload) =>
        SingleValueTooltip("xValue", payload, (value) =>
          t("ChartTooltip.CustomerCount", {
            value: getCustomerCountValue(value as number),
          })
        )
      }
    />
  );
}
