import {
  MEASUREMENT_PROPERTIES,
  RevenueType,
  CustomerLevel,
} from "common/constants";
import { Column } from "components/Table/models";
import {
  CategoriesAndPeriodsTableModel,
  CustomerByChangeCategoryChartModel,
  CustomersByChangeCategoryResponse,
} from "models/report";
import { ReportSettings } from "slices/models/reportSliceModel";
import { formatPeriodText, getCustomerCountValue } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";
import { ChartDatum } from "components/Charts/model";
import { Typography } from "@mui/material";

import { computeCustomerProductLevelCategories } from "./computeCustomerProductLevelCategories";
import {
  CUSTOMER_LEVEL_CHART_BARS,
  CUSTOMER_PRODUCT_LEVEL_CHART_BARS,
  CHART_BARS,
  CATEGORIES_BY_CUSTOMER_LEVEL,
} from "./customersByChangeCategory.constants";

function renderCategoryCell(category: string) {
  return (
    <Typography
      variant="body2"
      color={
        category === "Beginning Count"
          ? "var(--text-primary)"
          : "var(--text-secondary)"
      }
      sx={{
        fontWeight: category === "Beginning Count" ? 700 : 400,
        position: "relative",
        left: category === "Beginning Count" ? -16 : 0,
      }}
    >
      {category}
    </Typography>
  );
}

function renderValueCell(value: number, isBold = false) {
  return (
    <Typography
      variant="body2"
      color={isBold ? "var(--text-primary)" : "var(--text-secondary)"}
      sx={{ fontWeight: isBold ? 700 : 400 }}
    >
      {getCustomerCountValue(value)}
    </Typography>
  );
}
export function getCustomersByChangeCategoryTableColumns(
  periods: CustomerByChangeCategoryChartModel[]
): Column<CategoriesAndPeriodsTableModel>[] {
  return [
    {
      header: "",
      render: (data: CategoriesAndPeriodsTableModel) =>
        renderCategoryCell(data.category),
      footer: (
        <Typography
          variant="body2"
          color="var(--text-primary)"
          sx={{ fontWeight: 700, position: "relative", left: -16 }}
        >
          Ending Count
        </Typography>
      ),
      sx: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        textWrap: "nowrap",
      },
    },
    ...periods.map((period) => {
      const month = formatPeriodText(period.xValue);

      return {
        header: (
          <Typography
            variant="body2"
            align="center"
            sx={{ fontFamily: "Work Sans", fontWeight: 500 }}
          >
            {month}
          </Typography>
        ),
        render: (data: CategoriesAndPeriodsTableModel) =>
          renderValueCell(
            data[month] ?? 0,
            data.category === "Beginning Count"
          ),
        footer: renderValueCell(period["Ending Count"], true),
        sx: {
          textWrap: "nowrap",
        },
      };
    }),
  ];
}

export function getCustomersByChangeCategoryTableData(
  periods: CustomerByChangeCategoryChartModel[],
  customerLevel: CustomerLevel
): CategoriesAndPeriodsTableModel[] {
  const data = CATEGORIES_BY_CUSTOMER_LEVEL[customerLevel].map((category) => ({
    category,
  })) as CategoriesAndPeriodsTableModel[];

  for (const period of periods) {
    for (const [index, category] of data.entries()) {
      const month = formatPeriodText(period.xValue);

      category[month] = period[
        CATEGORIES_BY_CUSTOMER_LEVEL[customerLevel][index]
      ] as number;
    }
  }

  return data;
}

export function computeReportData(
  data: CustomersByChangeCategoryResponse[],
  reportSettings: ReportSettings
): CustomerByChangeCategoryChartModel[] {
  let groupedData: CustomersByChangeCategoryResponse[] = [];
  if (reportSettings.params.revenueType === RevenueType.Monthly)
    groupedData = getLastItemOfEachGroup(data, "Month");
  if (reportSettings.params.revenueType === RevenueType.Quarterly)
    groupedData = getLastItemOfEachGroup(data, "DISPLAYQTR");
  if (reportSettings.params.revenueType === RevenueType.Yearly)
    groupedData = getLastItemOfEachGroup(data, "FISCALYR");

  return groupedData.map((period) => {
    const key = MEASUREMENT_PROPERTIES[reportSettings.measurement];
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = period.Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = period.DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = period.FISCALYR;

    let computedPeriod = {
      xValue,
      "Beginning Count": period.StartingCustomers[key] || 0,
      "New Customer":
        (period["NEW CUSTOMER"] && period["NEW CUSTOMER"][key]) || 0,
      "Customer Reactivation":
        (period["CUSTOMER REACTIVATION"] &&
          period["CUSTOMER REACTIVATION"][key]) ||
        0,
      "Lost Customer":
        (period["LOST CUSTOMER"] && period["LOST CUSTOMER"][key]) || 0,
      "Ending Count": period.EndingCustomers[key] || 0,
      year: period.Month.split("-")[0],
    };

    if (reportSettings.params.customerLevel === CustomerLevel.CustomerProduct) {
      const { categories } = computeCustomerProductLevelCategories(
        period,
        xValue,
        key,
        {}
      );

      computedPeriod = { ...computedPeriod, ...categories };
    }
    return computedPeriod;
  });
}

export function getChartBars(
  customerLevel: CustomerLevel
): ChartDatum<CustomerByChangeCategoryChartModel>[] {
  if (customerLevel === CustomerLevel.CustomerProduct) {
    return CUSTOMER_PRODUCT_LEVEL_CHART_BARS.map(
      (category) => CHART_BARS[category]
    );
  }
  return CUSTOMER_LEVEL_CHART_BARS.map((category) => CHART_BARS[category]);
}
