import { ExpandMore } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import { useReportSelector } from "common/store";
import CollapsibleSelect from "components/Inputs/CollapsibleSelect";
import { KeyValue } from "models/common";
import { useEffect, useMemo, useState } from "react";
import { getSourceColumnName } from "utils/report";

interface Props {
  title: string;
  type: "segmentCustomers" | "segmentProducts";
  onSelect: (segment: string, selected: KeyValue[]) => void;
  disabled?: boolean;
}

export default function DashboardFilterSegment(props: Props) {
  const { reportData, reportSettings } = useReportSelector();

  const [totalCount, setTotalCount] = useState<{ [x: string]: number }>({});

  useEffect(() => {
    const newCount = Object.fromEntries(
      Object.entries(reportSettings.filters[props.type]).map(([key, value]) => [
        key,
        value.length,
      ])
    );
    setTotalCount(newCount);
  }, [reportSettings.filters[props.type]]);

  const selectedAll = useMemo(() => {
    const optionCounts = Object.fromEntries(
      (reportData[props.type] ?? []).map((x) => [x.segment, x.data.length])
    );

    return Object.entries(totalCount).every(
      ([key, count]) => count === 0 || count === optionCounts[key]
    );
  }, [reportData[props.type], totalCount]);

  function handleOnSelectChanged(segment: string, selected: KeyValue[]): void {
    setTotalCount((old) => ({ ...old, [segment]: selected.length }));
    props.onSelect?.(segment, selected);
  }

  return (
    <Accordion
      disabled={props.disabled}
      key={
        props.disabled
          ? `${reportSettings.filters[props.type]}_disabled`
          : `${reportSettings.filters[props.type]}_enabled`
      }
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {`${props.title}${selectedAll ? " (All)" : ""}`}
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <List disablePadding>
          {reportData[props.type]?.map((x) => (
            <CollapsibleSelect
              key={x.segment}
              options={x.data}
              value={reportSettings.filters[props.type][x.segment]!}
              onSelect={(values) => handleOnSelectChanged(x.segment, values)}
              title={getSourceColumnName(reportData.mapping, x.segment)!}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
