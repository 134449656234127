import i18n from "common/i18n";

export function formatNumericValue(
  value: number | null,
  keepMinus = false
): string {
  if (value === null || value === undefined) {
    return "";
  }
  const round = Number(Math.abs(value).toFixed(1));
  const formatted = `${round.toLocaleString()}`;

  if (value && value < 0) {
    return keepMinus ? `-${formatted}` : `(${formatted})`;
  }

  return `${formatted}`;
}

export function formatPercentValue(
  value: number | null,
  keepMinus = false
): string {
  const round = Number((Math.abs(value ?? 0) * 100).toFixed(1));
  const formatted = `${round.toLocaleString()}`;

  if (value && value < 0) {
    return keepMinus ? `-${formatted}%` : `(${formatted}%)`;
  }

  return `${formatted}%`;
}

export function formatCurrencyValue(
  value: number | "" | null | undefined,
  keepMinus = false,
  showZero = true,
  unit: "thousands" | "millions" = "thousands"
): string {
  if (!value && !showZero) {
    return "";
  }
  if (value === undefined || value === null || value === "") {
    return "";
  }
  let round = Math.abs(value);
  if (unit === "thousands") round = Number((round / 1000).toFixed(1));
  if (unit === "millions") round = Number((round / 1_000_000).toFixed(1));
  const formatted = `$${round.toLocaleString()}`;

  if (value < 0) {
    return keepMinus ? `-${formatted}` : `(${formatted})`;
  }

  return `${formatted}`;
}

export function getPeriodAxisLabel(value: string, xValues: string[]): string {
  if (
    value.startsWith("Q") &&
    xValues
      .filter((item) => {
        const year = item.split("-")[1];
        return year === value.split("-")[1];
      })
      .indexOf(value) === 0 &&
    (xValues.filter((item) => item.split("-")[1] === value.split("-")[1])
      .length > 2 ||
      xValues.length < 60)
  ) {
    return `20${value.split("-")[1]}`;
  } else if (
    !value.startsWith("Q") &&
    value.includes("-") &&
    xValues
      .filter((item) => item.split("-")[0] === value.split("-")[0])
      .indexOf(value) === 0 &&
    (xValues.filter((item) => item.split("-")[0] === value.split("-")[0])
      .length > 2 ||
      xValues.length < 60)
  ) {
    return value.split("-")[0];
  } else if (value.includes("FY")) {
    return value.replace("FY", "20");
  }
  return ".";
}

export function getCustomerCountValue(
  value: number,
  keepMinus = false
): string {
  const formatted = Number(Math.abs(value).toFixed(1)).toLocaleString();

  if (value < 0) {
    if (keepMinus) {
      return `-${formatted}`;
    }
    return `(${formatted})`;
  }

  return formatted;
}

const monthMapping: { [month: string]: string } = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

type PeriodFormat = "MMM-YY" | "MMM. YYYY";

export function formatPeriodText(
  period: string,
  format: PeriodFormat = "MMM-YY"
) {
  if (period.startsWith("Q") || period.startsWith("FY")) {
    return period;
  }

  const [year, month] = period.split("-"); // format YYYY-MM

  if (format === "MMM. YYYY") {
    return `${monthMapping[month]}. ${year}`;
  }
  return `${monthMapping[month]}-${year.slice(2, 4)}`;
}

export function formatFileSize(size: number): string {
  const units = ["bytes", "KB", "MB", "GB"];
  let unitIndex = 0;
  while (size >= 1000 && unitIndex < units.length - 1) {
    size /= 1000;
    unitIndex++;
  }
  return `${size.toFixed(1)} ${units[unitIndex]}`;
}

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export function formatPercentageValue(value: number): string {
  return `${Math.round(value * 100) / 100}%`;
}

export function formatUserDisplayName({
  email,
  firstName,
  lastName,
  fullName,
}: {
  email: string;
  firstName: string;
  lastName: string;
  fullName?: string;
}) {
  fullName ??= `${firstName} ${lastName}`;
  fullName = fullName.trim();

  if (fullName === "User Default" || !fullName) {
    return `${email} (${i18n.t("TeamUsers_DataGrid_ColValue_Invited")})`;
  }
  return fullName;
}
