import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getContractRenewalChart,
  getCountChangeChart,
  getCustomerCohortChart,
  getReportData,
  getRevenueByPeriodChart,
  getRollForwardChart,
  getRollForwardChartImpactVolume,
  getTopCustomerChart,
} from "services/reportService";
import error from "utils/error";
import { createAppThunk } from "common/appThunk";

import {
  ChartCacheData,
  ChartSettings,
  ReportFilters,
  ReportParams,
  ReportSettings,
  ReportState,
  defaultReportData,
  defaultReportSettings,
  initialState,
} from "./models/reportSliceModel";

function handleGetReportData(builder: ActionReducerMapBuilder<ReportState>) {
  builder
    .addCase(getReportData.pending, (state, action) => {
      state.reportData.isLoading = true;
      state.dashboardId = action.meta.arg.dashboardId;
    })
    .addCase(getReportData.fulfilled, (state, action) => {
      state.dashboardId = action.payload.file!.dashboardId;
      state.chartData = initialState.chartData;
      state.reportData = { ...action.payload, isLoading: false };
      const currentSettings = state.currentSettings[state.dashboardId];
      if (!currentSettings.filters.minDate) {
        currentSettings.filters.minDate = action.payload.minDate!;
        currentSettings.filters.defaultMinDate = action.payload.minDate!;
      }
      if (!currentSettings.filters.maxDate) {
        currentSettings.filters.maxDate = action.payload.maxDate!;
        currentSettings.filters.defaultMaxDate = action.payload.maxDate!;
      }
      state.originalSettings = state.currentSettings[state.dashboardId];
    })
    .addCase(getReportData.rejected, (state, action) => {
      state.reportData = {
        ...defaultReportData,
        isLoading: false,
        error: error(action.error),
      };
    });
}

function handleGetChartData<
  K extends keyof ChartCacheData,
  Return extends ChartCacheData[K]["data"],
  Params
>(
  cacheKey: K,
  thunk: ReturnType<typeof createAppThunk<Return, Params>>,
  builder: ActionReducerMapBuilder<ReportState>
) {
  builder
    .addCase(thunk.pending, (state, action) => {
      state.chartData[cacheKey].isLoading = true;
      state.chartData[cacheKey].currentRequestId = action.meta.requestId;
    })
    .addCase(thunk.fulfilled, (state: ReportState, action) => {
      const hasAnotherRequest =
        state.chartData[cacheKey].currentRequestId !== action.meta.requestId;

      state.originalSettings = state.currentSettings[state.dashboardId];
      state.chartData[cacheKey] = {
        // if there's another request pending, keep the loading state
        isLoading: hasAnotherRequest,
        data: action.payload,
        shouldFetch: hasAnotherRequest,
        // if the latest request is fulfilled, reset the current request ID
        currentRequestId: hasAnotherRequest
          ? state.chartData[cacheKey].currentRequestId
          : undefined,
      } as ChartCacheData[K];
    })
    .addCase(thunk.rejected, (state, action) => {
      const hasAnotherRequest =
        state.chartData[cacheKey].currentRequestId !== action.meta.requestId;

      state.chartData[cacheKey] = {
        isLoading: hasAnotherRequest,
        error: error(action.error),
        shouldFetch: hasAnotherRequest,
        currentRequestId: hasAnotherRequest
          ? state.chartData[cacheKey].currentRequestId
          : undefined,
      };
    });
}

const slice = createSlice({
  name: "reportFilter",
  initialState,
  reducers: {
    setDashboardId(state, action: PayloadAction<string>) {
      const dashboardId = action.payload;
      state.dashboardId = dashboardId;
      state.currentSettings[dashboardId] ??= defaultReportSettings;
    },
    setReportSettings(state, action: PayloadAction<Partial<ReportSettings>>) {
      const currentSettings = state.currentSettings[state.dashboardId];
      state.currentSettings[state.dashboardId] = {
        ...currentSettings,
        ...action.payload,
      };
    },
    setReportParams(state, action: PayloadAction<Partial<ReportParams>>) {
      const currentSettings = state.currentSettings[state.dashboardId];
      const currentParams = currentSettings.params;
      currentSettings.params = { ...currentParams, ...action.payload };
    },
    setReportFilters(state, action: PayloadAction<Partial<ReportFilters>>) {
      const currentSettings = state.currentSettings[state.dashboardId];
      const currentFilters = currentSettings.filters;
      currentSettings.filters = { ...currentFilters, ...action.payload };
    },
    setOriginalSettings(state, action: PayloadAction<ReportSettings>) {
      state.originalSettings = { ...action.payload };
    },
    setChartShouldFetch(
      state,
      action: PayloadAction<(keyof ChartCacheData)[]>
    ) {
      for (const chartKey of action.payload) {
        state.chartData[chartKey].shouldFetch = true;
      }
    },
    setChartSettings(state, action: PayloadAction<Partial<ChartSettings>>) {
      const chartSettings =
        state.currentSettings[state.dashboardId].chartSettings;
      let property: keyof typeof chartSettings;

      for (property in action.payload) {
        chartSettings[property] = {
          ...chartSettings[property],
          ...action.payload[property],
        };
      }
    },
  },
  extraReducers: (builder) => {
    handleGetReportData(builder);
    handleGetChartData("roll-forward", getRollForwardChart, builder);
    handleGetChartData(
      "roll-forward-impact-volume",
      getRollForwardChartImpactVolume,
      builder
    );
    handleGetChartData("overview", getRevenueByPeriodChart, builder);
    handleGetChartData("count-change", getCountChangeChart, builder);
    handleGetChartData("contract-renewal", getContractRenewalChart, builder);
    handleGetChartData("top-customers", getTopCustomerChart, builder);
    handleGetChartData("customer-cohorts", getCustomerCohortChart, builder);
  },
});

export const {
  setDashboardId,
  setReportSettings,
  setReportParams,
  setReportFilters,
  setOriginalSettings,
  setChartShouldFetch,
  setChartSettings,
} = slice.actions;

export default slice.reducer;
