import {
  CategoriesAndPeriodsTableModel,
  RevenueByChangeCategoryChartModel,
  RevenueByChangeCategoryResponse,
  RevenueByChangeCategoryVolumeImpactResponse,
} from "models/report";
import {
  CustomerLevel,
  MEASUREMENT_PROPERTIES,
  ReportMeasurement,
  RevenueType,
} from "common/constants";
import { Column } from "components/Table/models";
import { formatCurrencyValue, formatPeriodText } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";
import {
  ChartData,
  ReportData,
  ReportSettings,
} from "slices/models/reportSliceModel";
import { ChartDatum } from "components/Charts/model";
import { Typography } from "@mui/material";

import {
  CATEGORIES_BY_CUSTOMER_LEVEL,
  CUSTOMER_LEVEL_RF_CHART_BARS,
  CUSTOMER_PRODUCT_LEVEL_RF_CHART_BARS,
  CHART_BARS,
} from "./revenueByChangeCategoryReport.constants";
import { computeCustomerProductLevelCategories } from "./computeCustomerProductLevelCategories";

function getKeys(reportSettings: ReportSettings) {
  switch (reportSettings.measurement) {
    case ReportMeasurement.MRR: {
      return "SumMrr";
    }
    case ReportMeasurement.ARR: {
      return "SumArr";
    }
    case ReportMeasurement.CMRR: {
      return "SumCMrr";
    }
    case ReportMeasurement.CARR: {
      return "SumCArr";
    }
    // No default
  }
}

function getLastMonthFromDataSubRows(
  chartDataVolumeImpact: ChartData<
    RevenueByChangeCategoryVolumeImpactResponse[]
  >,
  groupBy: string
) {
  if (groupBy === "DISPLAYQTR") {
    return (
      chartDataVolumeImpact.data?.filter((item) => item.IsLastMonthOfQuarter) ||
      []
    );
  }
  if (groupBy === "FISCALYR") {
    return (
      chartDataVolumeImpact.data?.filter((item) => item.IsLastMonthOfYear) || []
    );
  }
  return chartDataVolumeImpact.data || [];
}
function handleVolumeImpactSubRows(
  chartDataVolumeImpact:
    | ChartData<RevenueByChangeCategoryVolumeImpactResponse[]>
    | undefined,
  subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] },
  reportSettings: ReportSettings
) {
  if (!chartDataVolumeImpact?.data || !subRows.Upsell) return;
  const UpsellPrice = {
    category: "Upsell Price",
  } as CategoriesAndPeriodsTableModel;
  const UpsellVolume = {
    category: "Upsell Volume",
  } as CategoriesAndPeriodsTableModel;
  const DownsellPrice = {
    category: "Downsell Price",
  } as CategoriesAndPeriodsTableModel;
  const DownsellVolume = {
    category: "Downsell Volume",
  } as CategoriesAndPeriodsTableModel;
  let groupedData: RevenueByChangeCategoryVolumeImpactResponse[] = [];
  let periodType = "Month" as "Month" | "DISPLAYQTR" | "FISCALYR";
  if (reportSettings.params.revenueType === RevenueType.Monthly) {
    groupedData = getLastMonthFromDataSubRows(chartDataVolumeImpact, "Month");
    periodType = "Month";
  }
  if (reportSettings.params.revenueType === RevenueType.Quarterly) {
    groupedData = getLastMonthFromDataSubRows(
      chartDataVolumeImpact,
      "DISPLAYQTR"
    );
    periodType = "DISPLAYQTR";
  }
  if (reportSettings.params.revenueType === RevenueType.Yearly) {
    groupedData = getLastMonthFromDataSubRows(
      chartDataVolumeImpact,
      "FISCALYR"
    );
    periodType = "FISCALYR";
  }
  for (const period of groupedData) {
    UpsellPrice[formatPeriodText(period[periodType])] =
      period.UPSELL?.[getKeys(reportSettings) + "PriceImpact"] || 0;
    UpsellVolume[formatPeriodText(period[periodType])] =
      period.UPSELL?.[getKeys(reportSettings) + "VolumeImpact"] || 0;
    DownsellPrice[formatPeriodText(period[periodType])] =
      period.DOWNSELL?.[getKeys(reportSettings) + "PriceImpact"] || 0;
    DownsellVolume[formatPeriodText(period[periodType])] =
      period.DOWNSELL?.[getKeys(reportSettings) + "VolumeImpact"] || 0;
  }
  subRows.Upsell.push(UpsellPrice, UpsellVolume);
  subRows.Downsell.push(DownsellPrice, DownsellVolume);
}

function renderCategoryCell(category: string) {
  return (
    <Typography
      variant="body2"
      color={
        category === "Beginning Balance"
          ? "var(--text-primary)"
          : "var(--text-secondary)"
      }
      sx={{
        fontWeight: category === "Beginning Balance" ? 700 : 400,
        position: "relative",
        left: category === "Beginning Balance" ? -16 : 0,
      }}
    >
      {category}
    </Typography>
  );
}

function renderValueCell(value: number, isBold = false) {
  return (
    <Typography
      variant="body2"
      color={isBold ? "var(--text-primary)" : "var(--text-secondary)"}
      sx={{ fontWeight: isBold ? 700 : 400 }}
    >
      {formatCurrencyValue(value, false, true)}
    </Typography>
  );
}

export function getRevenueByChangeCategoryTableColumns(
  periods: RevenueByChangeCategoryChartModel[]
): Column<CategoriesAndPeriodsTableModel>[] {
  return [
    {
      header: "",
      render: (data) => renderCategoryCell(data.category),
      footer: (
        <Typography
          variant="body2"
          color="var(--text-primary)"
          sx={{ fontWeight: 700, position: "relative", left: -16 }}
        >
          Ending Balance
        </Typography>
      ),
      sx: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        textWrap: "nowrap",
      },
    },
    ...periods.map((period) => {
      const month = formatPeriodText(period.xValue);

      return {
        header: (
          <Typography
            variant="body2"
            align="center"
            sx={{ fontFamily: "Work Sans", fontWeight: 500 }}
          >
            {month}
          </Typography>
        ),
        render: (data: CategoriesAndPeriodsTableModel) =>
          renderValueCell(
            data[month] ?? 0,
            data.category === "Beginning Balance"
          ),
        footer: renderValueCell(period["Ending Balance"], true),
        sx: {
          textWrap: "nowrap",
        },
      };
    }),
  ];
}

export function getRevenueByChangeCategoryTableData(
  periods: RevenueByChangeCategoryChartModel[],
  customerLevel: CustomerLevel
): CategoriesAndPeriodsTableModel[] {
  const data = CATEGORIES_BY_CUSTOMER_LEVEL[customerLevel].map((category) => ({
    category,
  })) as CategoriesAndPeriodsTableModel[];

  for (const period of periods) {
    for (const [index, category] of data.entries()) {
      const month = formatPeriodText(period.xValue);

      category[month] = period[
        CATEGORIES_BY_CUSTOMER_LEVEL[customerLevel][index]
      ] as number;
    }
  }

  return data;
}

export function computeReportData(
  data: RevenueByChangeCategoryResponse[],
  reportSettings: ReportSettings,
  reportData?: ReportData,
  chartDataVolumeImpact?: ChartData<
    RevenueByChangeCategoryVolumeImpactResponse[]
  >
): {
  computedData: RevenueByChangeCategoryChartModel[];
  subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] };
} {
  let groupedData: RevenueByChangeCategoryResponse[] = [];
  if (reportSettings.params.revenueType === RevenueType.Monthly)
    groupedData = getLastItemOfEachGroup(data, "Month");
  if (reportSettings.params.revenueType === RevenueType.Quarterly)
    groupedData = getLastItemOfEachGroup(data, "DISPLAYQTR");
  if (reportSettings.params.revenueType === RevenueType.Yearly)
    groupedData = getLastItemOfEachGroup(data, "FISCALYR");
  const subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] } = {
    "Cross-Sell": [],
    "Lost Product": [],
  };
  if (
    reportSettings.params.customerLevel === CustomerLevel.Customer &&
    reportData?.file?.mapping?.some(
      (x) => x.mdtName === "Quantity" && x.sourceName
    )
  ) {
    subRows.Upsell = [];
    subRows.Downsell = [];
  }
  const computedData = groupedData.map((period) => {
    const key = MEASUREMENT_PROPERTIES[reportSettings.measurement];
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = period.Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = period.DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = period.FISCALYR;

    let computedPeriod: RevenueByChangeCategoryChartModel = {
      xValue,
      "Beginning Balance": period.BeginningBalance[key] || 0,
      "New Customer":
        (period["NEW CUSTOMER"] && period["NEW CUSTOMER"][key]) || 0,
      "Customer Reactivation":
        (period["CUSTOMER REACTIVATION"] &&
          period["CUSTOMER REACTIVATION"][key]) ||
        0,
      Upsell: (period.UPSELL && period.UPSELL[key]) || 0,
      Downsell: (period.DOWNSELL && period.DOWNSELL[key]) || 0,
      "Lost Customer":
        (period["LOST CUSTOMER"] && period["LOST CUSTOMER"][key]) || 0,
      "Ending/Beginning Revenue Credits":
        (period["BEGINNING/ENDING REVENUE CREDITS"] &&
          period["BEGINNING/ENDING REVENUE CREDITS"][key]) ||
        0,
      "Cust Segment Migration":
        (period["CUSTOMER SEGMENT MIGRATION"] &&
          period["CUSTOMER SEGMENT MIGRATION"][key]) ||
        0,
      "Ending Balance": period.EndingBalance[key] || 0,
      year: period.Month.split("-")[0],
      month: period.Month,
    };

    if (reportSettings.params.customerLevel === CustomerLevel.CustomerProduct) {
      const { categories, crossSellSubCategories, lostProductSubCategories } =
        computeCustomerProductLevelCategories(period, xValue, key, subRows);

      computedPeriod = { ...computedPeriod, ...categories };

      subRows["Cross-Sell"] = crossSellSubCategories;
      subRows["Lost Product"] = lostProductSubCategories;
    }
    return computedPeriod;
  });

  handleVolumeImpactSubRows(chartDataVolumeImpact, subRows, reportSettings);

  return {
    computedData,
    subRows,
  };
}

export function getChartBars(
  customerLevel: CustomerLevel
): ChartDatum<RevenueByChangeCategoryChartModel>[] {
  if (customerLevel === CustomerLevel.CustomerProduct) {
    return CUSTOMER_PRODUCT_LEVEL_RF_CHART_BARS.map(
      (category) => CHART_BARS[category]
    );
  }
  return CUSTOMER_LEVEL_RF_CHART_BARS.map((category) => CHART_BARS[category]);
}
