import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CustomerLevel, ReportMeasurement } from "common/constants";
import { useReportSelector, useSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import lodash from "lodash";
import lodashFindLast from "lodash/findLast";
import {
  RevenueByChangeCategoryChartModel,
  RevenueByChangeCategoryResponse,
} from "models/report";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getRollForwardChart,
  getRollForwardChartImpactVolume,
} from "services/reportService";
import { computeReportData } from "utils/chartUtils/revenueByChangeCategoryReport";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { setChartShouldFetch } from "slices/reportSlice";

import RevenueChangeDrillDownTable from "../charts/RevenueChangeDrillDownTable";
import RollForwardChart from "../charts/RollForward/RollForwardChart";
import RollForwardTable from "../charts/RollForward/RollForwardTable";
import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";

export default function SectionRollForward() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reportData, reportSettings } = useReportSelector();

  const chartData = useSelector(
    (state) => state.report.chartData["roll-forward"]
  );
  const chartDataVolumeImpact = useSelector(
    (state) => state.report.chartData["roll-forward-impact-volume"]
  );

  const [viewToggle, setViewToggle] = useState(["chart"]);
  const [cMeasure, setCMeasure] = useState<boolean>(false);
  const [selectedPeriod, setPeriod] = useState<string>();
  const [selectedCategory, setCategory] =
    useState<keyof RevenueByChangeCategoryChartModel>();
  const [prevCategory, setPrevCategory] = useState<string>();

  useEffect(() => {
    if (reportData.isLoading || !chartData.shouldFetch) return;
    dispatch(getRollForwardChart());
    if (
      reportSettings.params.customerLevel === CustomerLevel.Customer &&
      reportData.file?.mapping?.some(
        (x) => x.mdtName === "Quantity" && x.sourceName
      )
    ) {
      dispatch(getRollForwardChartImpactVolume());
    }
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["roll-forward"]));
    if (
      reportSettings.params.customerLevel === CustomerLevel.Customer &&
      reportData.file?.mapping?.some(
        (x) => x.mdtName === "Quantity" && x.sourceName
      )
    ) {
      dispatch(setChartShouldFetch(["roll-forward-impact-volume"]));
    }
  }, [
    cMeasure,
    reportSettings.params.customerLevel,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  useEffect(() => {
    const hasCMeasure =
      reportSettings.measurement === ReportMeasurement.CARR ||
      reportSettings.measurement === ReportMeasurement.CMRR;
    setCMeasure(hasCMeasure);
  }, [reportSettings.measurement]);

  useEffect(() => {
    if (!prevCategory && selectedCategory) {
      setViewToggle(["chart"]);
    }
  }, [selectedCategory, prevCategory]);

  useEffect(() => {
    setCategory(undefined);
    setPeriod(undefined);
    setPrevCategory(undefined);
  }, [
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.customers,
    reportSettings.params.reactivationThreshold,
    reportSettings.params.analysisType,
    reportSettings.params.revenueType,
    reportSettings.measurement,
  ]);

  // Report data with filters applied, ready to put into chart
  function getDisplayReportData() {
    if (chartData.data === undefined) return { computedData: [], subRows: {} };
    if (!reportSettings.filters.minDate || !reportSettings.filters.maxDate)
      return { computedData: [], subRows: {} };

    const computedData = chartData.data as RevenueByChangeCategoryResponse[];

    const startIndex = lodash.findIndex(
      computedData,
      (x) => x.Month >= reportSettings.filters.minDate!.slice(0, -3)
    );
    const endIndex = lodash.findLastIndex(
      computedData,
      (x) => x.Month <= reportSettings.filters.maxDate!.slice(0, -3)
    );

    if (startIndex === -1 || endIndex === -1)
      return { computedData: [], subRows: {} };

    const filtered = computedData.slice(startIndex, endIndex + 1);
    return computeReportData(
      filtered,
      reportSettings,
      reportData,
      chartDataVolumeImpact
    );
  }
  const displayableReportData = getDisplayReportData();

  const monthOfSelectedPeriod = useMemo(() => {
    const p = lodashFindLast(
      displayableReportData.computedData,
      (item: RevenueByChangeCategoryChartModel) =>
        item.xValue === selectedPeriod
    );
    return p?.month || selectedPeriod;
  }, [selectedPeriod, displayableReportData.computedData]);

  const drillldownStartMonth = useMemo(() => {
    return displayableReportData
      ? displayableReportData.computedData[0]?.month || ""
      : "";
  }, [displayableReportData.computedData]);

  const drillldownEndMonth = useMemo(() => {
    return displayableReportData
      ? displayableReportData.computedData.at(-1)?.month || ""
      : "";
  }, [displayableReportData.computedData]);

  const handleOnNextPeriod = useCallback(() => {
    if (!selectedPeriod) {
      return;
    }
    const currentPeriodIndex = displayableReportData.computedData.findIndex(
      (item) => item.xValue === selectedPeriod
    );
    setPeriod(
      displayableReportData.computedData[currentPeriodIndex + 1].xValue
    );
  }, [selectedPeriod]);

  const handleOnPrevPeriod = useCallback(() => {
    if (!selectedPeriod) {
      return;
    }
    const currentPeriodIndex = displayableReportData.computedData.findIndex(
      (item) => item.xValue === selectedPeriod
    );
    setPeriod(
      displayableReportData.computedData[currentPeriodIndex - 1].xValue
    );
  }, [selectedPeriod]);

  useEffect(() => {
    setCategory(undefined);
  }, [reportSettings.params.customerLevel]);

  const handleDoneClick = useCallback(() => {
    setCategory(undefined);
    setPeriod(undefined);
  }, []);

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionRollForward", {
          view: ReportMeasurement[reportSettings.measurement],
        })}
      </Typography>
      <GradientCard
        title={t(
          reportSettings.params.customerLevel === CustomerLevel.Customer
            ? "Dashboard.Charts.RollForwardTitle"
            : "Dashboard.Charts.RollForwardCustomerProductTitle",
          {
            view: ReportMeasurement[reportSettings.measurement],
          }
        )}
        subheader={
          <Typography variant="body2" fontStyle="italic">
            {t("Dashboard.DollarsInThounsands")}
          </Typography>
        }
        onViewToggle={(x) => setViewToggle(x)}
        onMoreClick={() => console.log("more")}
        viewToggleValue={viewToggle}
      >
        <WrapperContainer
          isLoading={reportData.isLoading || chartData.isLoading}
          error={chartData.error || reportData.error}
          isNoData={displayableReportData.computedData.length === 0}
        >
          {viewToggle.includes("chart") && (
            <RollForwardChart
              height={chartMinHeight}
              data={displayableReportData.computedData}
              onDrilldownExit={() => {
                setCategory(undefined);
                setPeriod(undefined);
              }}
              selectedCategory={selectedCategory}
              selectedMonth={selectedPeriod}
              onSelectCategory={(category) => {
                setPrevCategory(selectedCategory);
                setCategory(
                  category as keyof RevenueByChangeCategoryChartModel
                );
              }}
              onSelectMonth={setPeriod}
            />
          )}
          {viewToggle.includes("data") && (
            <Box sx={{ marginTop: 3, width: "100%" }}>
              <RollForwardTable
                data={displayableReportData.computedData}
                subRows={displayableReportData.subRows}
              />
            </Box>
          )}
          {selectedCategory && selectedPeriod && monthOfSelectedPeriod && (
            <Box sx={{ marginTop: 3, width: "100%" }}>
              <RevenueChangeDrillDownTable
                fileId={reportData.file!.id}
                period={selectedPeriod}
                monthOfPeriod={monthOfSelectedPeriod}
                category={selectedCategory}
                startMonth={drillldownStartMonth}
                endMonth={drillldownEndMonth}
                customerLevel={reportSettings.params.customerLevel}
                onNextPeriod={handleOnNextPeriod}
                onPrevPeriod={handleOnPrevPeriod}
                onDone={handleDoneClick}
              />
            </Box>
          )}
        </WrapperContainer>
      </GradientCard>
    </>
  );
}
