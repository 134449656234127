import {
  RevenueByChangeCategoryResponse,
  RevenueMeasurements,
  CategoriesAndPeriodsTableModel,
  CustomersByChangeCategoryResponse,
} from "models/report";
import { formatPeriodText } from "utils/format";

function subRowsSortsFn(
  a: CategoriesAndPeriodsTableModel,
  b: CategoriesAndPeriodsTableModel
) {
  const aIndex = Number.parseInt(a.category.match(/\d+/)![0], 10);
  const bIndex = Number.parseInt(b.category.match(/\d+/)![0], 10);

  return aIndex - bIndex;
}

export function computeCustomerProductLevelCategories(
  period: RevenueByChangeCategoryResponse | CustomersByChangeCategoryResponse,
  xAxisValue: string,
  valueKey: keyof RevenueMeasurements,
  currentSubRows: { [categories: string]: CategoriesAndPeriodsTableModel[] }
) {
  const categories: {
    "Cross-Sell"?: number;
    "Lost Product"?: number;
    "Product Reactivation"?: number;
  } = {};
  const crossSellSubCategories: CategoriesAndPeriodsTableModel[] =
    currentSubRows["Cross-Sell"] || [];
  const lostProductSubCategories: CategoriesAndPeriodsTableModel[] =
    currentSubRows["Lost Product"] || [];

  categories["Cross-Sell"] = 0;
  categories["Lost Product"] = 0;
  categories["Product Reactivation"] = 0;

  const formattedPeriod = formatPeriodText(xAxisValue);

  for (const [category, measurements] of Object.entries(period)) {
    const value: number = measurements[valueKey];

    if (category.startsWith("CROSS-SELLPRODUCT")) {
      categories["Cross-Sell"] = categories["Cross-Sell"] + value;

      const displayCategory = category.replace(
        "CROSS-SELLPRODUCT",
        "Cross-Sell "
      );
      const currentRow = crossSellSubCategories.find(
        (r) => r.category === displayCategory
      );
      if (currentRow) {
        currentRow[formattedPeriod] = value;
      } else {
        crossSellSubCategories.push({
          category: displayCategory,
          [formattedPeriod]: value,
        } as CategoriesAndPeriodsTableModel);
      }
    }
    if (category.startsWith("LOST-PRODUCTPRODUCT")) {
      categories["Lost Product"] = categories["Lost Product"] + value;

      const displayCategory = category.replace(
        "LOST-PRODUCTPRODUCT",
        "Lost Product "
      );
      const currentRow = lostProductSubCategories.find(
        (r) => r.category === displayCategory
      );
      if (currentRow) {
        currentRow[formattedPeriod] = value;
      } else {
        lostProductSubCategories.push({
          category: displayCategory,
          [formattedPeriod]: value,
        } as CategoriesAndPeriodsTableModel);
      }
    }
    if (category.startsWith("PRODUCT REACTIVATIONPRODUCT")) {
      categories["Product Reactivation"] =
        categories["Product Reactivation"] + value;
    }
  }
  crossSellSubCategories.sort(subRowsSortsFn);
  lostProductSubCategories.sort(subRowsSortsFn);

  return {
    categories,
    crossSellSubCategories,
    lostProductSubCategories,
  };
}
