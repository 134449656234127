import { AddOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import getDashboardsByWorkspace from "common/client/dashboardManagerClient";
import getWorkspace from "common/client/teamManagementClient";
import { FileActivityText, FileStatus, ROUTE_PATHS } from "common/constants";
import { useSelector } from "common/store";
import InviteWorkSpaceMemberModal from "containers/InviteWorkSpaceMemberModal";
import { WORKSPACE_ROUTES } from "containers/Workspace/config";
import { sortBy } from "lodash";
import { FileModel } from "models/fileModel";
import { Role } from "models/team";
import { WorkspaceModel } from "models/workspace";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Params,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { archiveWorkspace, deleteWorkspace } from "services/workspaceService";
import { selectRoleByTeam } from "slices/teamSlice";
import { useAlert } from "utils/hook/useAlert";
import { useToast } from "utils/hook/useNotification";
import { useFileSocket } from "utils/hook/useSocket";

import ContextMenu from "./components/ContextMenu";
import DashboardCard from "./components/DashboardCard";
import DashboardStatus from "./components/DashboardStatus";
import DashboardsAppBar from "./components/DashboardsAppBar";

interface PageData {
  error?: boolean;
  wsId: string;
  workspace?: WorkspaceModel;
  latestDashboard?: FileModel;
}

export default function DashboardsPage() {
  const data = useLoaderData() as PageData;

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { showError, showSuccess } = useToast();

  const [latestDashboard, setLatestDashboard] = useState<FileModel>();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const role = useSelector(selectRoleByTeam);
  const { isSystemAdmin } = useSelector((state) => state.team);

  const canEditWorkspace = isSystemAdmin || role === Role.Admin;

  useFileSocket<FileModel>((payload) => {
    if (payload.data.workspaceId === data.wsId)
      setLatestDashboard(payload.data);
  });

  useEffect(() => {
    if (data.latestDashboard !== undefined)
      setLatestDashboard(data.latestDashboard);
  }, [data.latestDashboard]);

  useEffect(() => {
    if (data.error) showError({ message: "Unknown error" });
  }, [data.error]);

  const handleOnDeleteOptionClick = (workspace: WorkspaceModel) => {
    showAlert({
      title: t("Workspace.Actions.Delete.Title"),
      message: t("Workspace.Actions.Delete.Description"),
      confirmLabel: t("Workspace.Actions.Delete.Action"),
      cancelLabel: "Cancel",
      onConfirm: () => {
        deleteWorkspace(workspace.id)
          .then(() => {
            showSuccess({
              message: (
                <>
                  <i>{workspace.name}</i>
                  {t("Workspace.Actions.Delete.SuccesMessage")}
                </>
              ),
            });
            navigate(
              `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
            );
          })
          .catch(() => {
            showError({
              message: t("Workspace.Actions.Delete.FailedMessage"),
            });
          });
      },
    });
  };

  const handleOnArchiveOptionClick = (workspace: WorkspaceModel) => {
    showAlert({
      title: t("Workspace.Actions.Archive.Title"),
      message: t("Workspace.Actions.Archive.Description"),
      confirmLabel: t("Workspace.Actions.Archive.Action"),
      cancelLabel: "Cancel",
      onConfirm: () => {
        archiveWorkspace(workspace.id)
          .then(() => {
            showSuccess({
              message: (
                <>
                  <i>{workspace.name}</i>
                  {t("Workspace.Actions.Archive.SuccesMessage")}
                  <u>{t("Common.GrowthEdgeCustomerCare")}</u>.
                </>
              ),
            });
            navigate(
              `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`
            );
          })
          .catch(() => {
            showError({
              message: t("Workspace.Actions.Archive.FailedMessage"),
            });
          });
      },
    });
  };

  function isError(dashboard?: FileModel) {
    return !!dashboard?.errorSummary || !!dashboard?.message;
  }

  function getLastActivity(dashboard?: FileModel) {
    if (
      !dashboard ||
      !dashboard.status ||
      !FileActivityText[dashboard.status]
    ) {
      return "";
    }

    if (isError(dashboard)) {
      if (dashboard.status === FileStatus.DataValidated) {
        return "Data failed to be submitted ";
      }

      return "Dashboard failed to generate ";
    }

    let activityText = FileActivityText[dashboard.status];
    if (
      dashboard.isSourceChanged &&
      dashboard.status === FileStatus.DataImported
    ) {
      activityText = FileActivityText[FileStatus.DataRemapped];
    }

    if (dashboard.status === FileStatus.ReportGenerated) {
      return `${activityText} `;
    }

    return `${
      dashboard.lastActivityUser || dashboard.updatedBy
    } ${activityText} `;
  }

  function handleOnOptionSelect(optionId: string): void {
    if (optionId === "archive") handleOnArchiveOptionClick(data.workspace!);
    if (optionId === "delete") handleOnDeleteOptionClick(data.workspace!);
  }

  function handleOnReportNameClick(): void {
    const status = latestDashboard?.status ?? -1;
    switch (status) {
      case FileStatus.ReportGenerated: {
        if (!isError(latestDashboard)) {
          navigate(
            `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${
              data.wsId
            }/${latestDashboard!.dashboardId}/${ROUTE_PATHS.WORKSPACES_REPORT}`
          );
        }
        break;
      }
      case FileStatus.DataImported: {
        navigate(
          `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${data.wsId}/${latestDashboard?.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA}`
        );
        break;
      }
      case FileStatus.DataValidated: {
        navigate(
          `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${data.wsId}/${latestDashboard?.dashboardId}/${WORKSPACE_ROUTES.CUSTOMER_DATA_REVIEW}`
        );
        break;
      }
    }
  }

  return (
    <Stack sx={{ minHeight: "100vh", backgroundColor: "var(--bg-color)" }}>
      <DashboardsAppBar workspace={data.workspace?.name} />
      <Box sx={{ padding: 3 }}>
        <Card>
          <CardHeader
            title={data.workspace?.name}
            sx={{
              backgroundImage: `var(--pattern-card-background-image)`,
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              color: "var(--white)",
            }}
            action={
              <Stack direction="row" spacing={1}>
                {canEditWorkspace && (
                  <div>
                    <Button
                      variant="text"
                      startIcon={<AddOutlined />}
                      sx={{ color: "white" }}
                      onClick={() => setIsInviteModalOpen(true)}
                    >
                      {t("Dashboards.Card.TeamMember")}
                    </Button>
                    <ContextMenu onOptionSelect={handleOnOptionSelect} />
                  </div>
                )}
              </Stack>
            }
          />
          <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
            <Stack spacing={2}>
              <DashboardCard
                dashboardType="RRI"
                createdAt={latestDashboard?.createdAt}
                createdBy={
                  latestDashboard?.createdByUser || latestDashboard?.createdBy
                }
                updatedAt={latestDashboard?.updatedAt}
                updatedBy={latestDashboard?.updatedBy}
                dashboardId={latestDashboard?.dashboardId}
                lastActivity={getLastActivity(latestDashboard)}
                isPlaceholder={!latestDashboard}
                status={latestDashboard?.status}
                isError={isError(latestDashboard)}
                role={data.workspace?.role}
                onCreateReportClick={() =>
                  navigate(
                    `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${data.wsId}/${WORKSPACE_ROUTES.IMPORT}`
                  )
                }
                onReportNameClick={handleOnReportNameClick}
              >
                <DashboardStatus
                  workspaceId={data.wsId}
                  dashboardId={latestDashboard?.dashboardId ?? ""}
                  wsRole={data.workspace?.role}
                  teamRole={role}
                  status={latestDashboard?.status ?? -1}
                  isError={isError(latestDashboard)}
                />
              </DashboardCard>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <InviteWorkSpaceMemberModal
        open={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        workspaceName={data.workspace?.name ?? ""}
        workspaceId={data.wsId}
      />
    </Stack>
  );
}

export async function loader({
  params,
}: {
  params: Params<"wsId">;
}): Promise<PageData> {
  const wsId = params.wsId ?? "";
  const workspace = await getWorkspace(wsId);

  const files = await getDashboardsByWorkspace(wsId);
  const latestFile = sortBy(files, (x) => x.createdAt).at(-1);
  return {
    wsId,
    workspace,
    latestDashboard: latestFile,
  };
}
